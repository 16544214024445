<template>
  <div class="detail-group">
    <div v-if="!isShowRequest">
      <Back
        :title="communityGroup.name"
        :header-avatar="communityGroup.imageUrl"
      />
      <div v-if="isJoin">
        <MyCommuGroupMenuBar
          :share="tabShare"
          @type_fetch="fetchPots"
        />
      </div>
      <div v-else>
        <MyCommuTabGroup />
      </div>
      <div class="detail">
        <div v-if="currentPage === 'partner'">
          <MyCommuGroupAreaPartners />
        </div>
        <div v-else>
          <div class="image-group">
            <img
              :src="communityGroup.imageUrl"
              alt=""
            >
          </div>
          <div class="info-group">
            <div class="name-and-action">
              <h1 class="title-group">
                {{ communityGroup.name }}
              </h1>
              <BDropdown v-if="editGroup" :mobile-modal="false" position="is-bottom-left">
                <button slot="trigger" class="option-button option-button-horizontal" type="button">
                  <BIcon pack="far" icon="ellipsis-v" />
                </button>
                <div class="co-dropdown-item edit" @click="goToEdit">
                  <span>編集する</span>
                </div>
              </BDropdown>
            </div>
            <div class="join-container">
              <p v-if="checkPublicGroup" class="count-member">
                参加メンバー：<a :href="urlListUser(communityGroup.id)">{{ groupUsers }}人</a>
              </p>
              <p v-else class="count-member">
                参加メンバー：<span>{{ groupUsers }}人</span>
              </p>
              <div class="group-statuses">
                <div v-if="communityGroup.privateMode">
                  <MyCommuGroupStatus value='非公開'/>
                </div>
                <div v-if="communityGroup.allowOnlyPeople" class="mt-4px ml-10px">
                  <MyCommuGroupStatus value='招待制'/>
                </div>
                <div v-if="!communityGroup.allowOnlyPeople && !communityGroup.privateMode" class="mt-4px">
                  <MyCommuGroupStatus value='誰でも参加可能' :is-special="true"/>
                </div>
              </div>
            </div>
            <div class="joined-list-user">
              <MyCommuJoinedUser
                :users="communityGroupUsers"
                :url="urlListUser(communityGroup.id)"
                :is-allow-only-people="communityGroup.allowOnlyPeople"
                :is-private-mode="communityGroup.privateMode"
              />
            </div>
            <p
              class="description"
              v-html="communityGroup.description"
            />
            <div class="button-join">
              <div
                v-if="isJoin"
                class="join-group"
              >
                <span class="icon-status-join">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="#2DBA87"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.97998 13.16L11.13 15.5L16.02 8.5"
                      stroke="#2DBA87"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span class="status-join">参加済み</span>
              </div>
              <BButton
                v-else-if="!communityGroup.allowOnlyPeople"
                class="is-fullwidth bg-white is-secondary fw-bold"
                type="is-success"
                outlined
                @click="joinGroup"
              >
                参加する
              </BButton>
              <BButton
                v-else
                class="is-fullwidth bg-white is-secondary fw-bold"
                type="is-success"
                outlined
                @click="isOpenJoinPass = true"
              >
                参加する
              </BButton>
            </div>
          </div>
        </div>
        <div class="event-filter" v-if="currentPage === 'event'">
          <EventTags v-if="false" />
          <MyCommuBaseButton
            :icon="plusIcon"
            text-content="交流イベントを開催する"
            :is-white-button="true"
            @clickButton="redirectEventNew()"
          />
          <b-modal
            :active.sync="modal"
            has-modal-card
          >
            <div class="modal-card">
              <div class="modal-card-body">
                <p>交流イベント作成できるのはシェ</p>
                <p>ア・コンシェルジュの方のみになり</p>
                <p>ます。</p>
                <MyCommuBaseButton
                  class="button-supporter"
                  text-content="シェア・コンシェルジュになる"
                  @clickButton="goToAsmamaEventMeeting()"
                />
                <p>シェア・コンシェルジュ以外の方は</p>
                <p>こちらから交流イベント作成のリク</p>
                <p>エストを送る事ができます。</p>
                <MyCommuBaseButton
                  class="button-create"
                  text-content="リクエストする"
                  @clickButton="sendRequest()"
                />
                <p
                  class="cancel"
                  @click="modal = false"
                >
                  閉じる
                </p>
              </div>
            </div>
          </b-modal>
        </div>
        <div v-if="isJoin" class="list-topic">
          <div class="topics">
            <div
              v-for="(post, index) in posts"
              :key="index"
            >
              <Topic
                v-if="post.sharingType === 'topic'"
                :topic="post"
                :path="`/communities/${communityId}/community_groups/${post.organizationGroupId}/community_group_topics/${post.id}`"
                :topic_group="true"
                :joined="isJoin"
                @confirmJoin="isjoinModalActive= true"
              />
              <MyCommuCardEvent
                v-if="post.sharingType === 'events'"
                :path="post.organizationPath"
                :event="post"
              />
              <ShareCard
                v-if="post.sharingType === 'giving' || post.sharingType === 'entry' || post.sharingType === 'living' || post.sharingType === 'lending' || post.sharingType === 'taking' || post.sharingType === 'borrowing'"
                class="post-space"
                :share="post"
                :share-path="post.path"
              />
              <NoticeJoinGroup :user="post" v-if="post.sharingType === 'user'" :is-in-group="true"/>
              <NoticeEvent v-if="post.sharingType === 'events' && groupCurrentTab !== 'event'" :notice="post" :user="post.eventHost" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-topic">
      <BModal
        :active.sync="isjoinModalActive"
        has-modal-card
        class="join-modal-active"
      >
        <div class="modal-card">
          <section class="modal-card-body">
            <div class="content">
              <span>
                投稿やシェアリングをするには
                このグループに参加する必要が
                あります。
              </span>
            </div>
          </section>
          <section class="modal-card-footer">
            <button
              class="button cancel"
              @click="isjoinModalActive = false"
            >
              キャンセル
            </button>
            <button
              v-if="!communityGroup.allowOnlyPeople"
              class="button ok fw-bold"
              @click="joinGroup"
            >
              参加する
            </button>
            <button
              v-else
              class="button ok fw-bold"
              @click="isOpenJoinPass = true"
            >
              参加する
            </button>
          </section>
        </div>
      </BModal>
      <BModal
        :active.sync="isOpenJoinPass"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        close-button-aria-label="Close"
        aria-modal
        class="join-pass"
      >
        <div
          class="modal-card"
        >
          <section
            v-if="!openConfirm"
            class="modal-card-body card-join-pass"
          >
            <p class="header">認証コードを入力する</p>
            <div class="form-join-pass">
              <MyCommuBaseTextInput
                id="key-join-group"
                v-model="keyJoinGroup"
                name="key-join-group"
              />
            </div>
            <div class="submit-form">
              <MyCommuBaseButton
                text-content="参加する"
                :disable="keyJoinGroup === ''"
                @clickButton="joinGroupForPass"
              />
            </div>
            <div class="confirm-admin">
              <p>認証コードがわからない場合は</p>
              <p>グループ管理者にお問い合わせください</p>
            </div>
            <div class="confirm-button">
              <MyCommuBaseButton
                text-content="グループ管理者に問い合わせる"
                @clickButton="openConfirm = true"
                :is-white-button="true"
              />
            </div>
          </section>
          <section
            v-else
            class="modal-card-body card-join-pass"
          >
            <div class="header-confirm-admin">
              <img src="../../images/icons/arr_left.svg" @click="openConfirm = false">
              <p>
                グループ管理者に問い合わせる
              </p>
              <div class="face-block">
              </div>
            </div>
            <div class="body-confirm-admin">
              <p>グループに参加したい理由を入力してください</p>
              <MyCommuBaseTextInput
                label="参加理由"
                name="body"
                placeholder="グループ参加理由"
                type="textarea"
                class="body-confirm"
                v-model="joinRequest"
              />
              <MyCommuBaseButton
                text-content="送信する"
                @clickButton="sendConfirm"
              />
            </div>
          </section>
        </div>
      </BModal>
      <BModal
        :active.sync="isSendDone"
        has-modal-card
        :can-cancel="true"
        :on-cancel="redirectListGroup"
        class="join-modal-active send-done"
      >
        <div class="modal-card">
          <section class="modal-card-body">
            <div class="content">
              <div class="image">
                <img src="../../images/for_community/circle-check.png">
              </div>
              <p>送信しました</p>
              <p>連絡をお待ちください</p>
            </div>
          </section>
        </div>
      </BModal>
    </div>
    <Request v-if="isShowRequest" @clickBack="clickBack"/>
  </div>
</template>

<script>
import Back from '../../components/my-commu/Back'
import Topic from '../../components/my-commu/card/Topic'
import { mapActions, mapState } from 'vuex'
import axios from '../../utils/axios'
import ShareCard from '../SharePost/ShareCard.vue'
import { commuError, commuSuccess } from '../../lib/app-toast'
import Request from '../event/Request'
export default {
  components: { Topic, Back, ShareCard, Request },
  props: {
    communityGroup: {
      type: Object,
      require: true
    },
    communityGroupTopic: {
      type: Array,
      default: () => []
    },
    communityGroupUsers: {
      type: Array,
      default: () => []
    },
    communityGroupComment: {
      type: Array,
      default: () => []
    },
    tabShare: {
      type: Boolean,
      default: false
    },
    editGroup: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isSendDone: false,
      isShowRequest: false,
      isjoinModalActive: false,
      isJoin: this.communityGroup.joined,
      groupUsers: this.communityGroup.count_user,
      plusIcon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<g clip-path="url(#clip0_10743_25754)">\n' +
          '<path d="M12 2.70996V21.29" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '<path d="M21.2909 12H2.71094" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '</g>\n' +
          '<defs>\n' +
          '<clipPath id="clip0_10743_25754">\n' +
          '<rect width="24" height="24" fill="white"/>\n' +
          '</clipPath>\n' +
          '</defs>\n' +
          '</svg>\n',
      keyJoinGroup: '',
      isOpenJoinPass: false,
      currentPage: 'all',
      openConfirm: false,
      joinRequest: '',
      modal: false,
    }
  },
  computed: {
    ...mapState('mycommu', ['currentCommunity']),
    ...mapState('session', ['communityId', 'currentUser']),
    ...mapState('groups', ['posts', 'groupCurrentTab']),
    checkPublicGroup () {
      return !this.communityGroup.allowOnlyPeople && !this.communityGroup.privateMode
    }
  },
  mounted () {
    this.toggerHeader(true)
    this.toggerMyCommuButton(this.isJoin)
    if (this.tabShare) {
      this.fetchPots('share')
    } else {
      this.fetchPosts()
    }
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader'
    ]),
    ...mapActions('groups', [
      'fetchPosts'
    ]),
    async joinGroupForPass () {
      const form = new FormData()
      form.append('community_group_user[organization_group_id]', this.communityGroup.id)
      form.append('community_group_user[key]', this.keyJoinGroup)
      await axios({
        method: 'POST',
        url: `/communities/${this.currentCommunity.community_id}/community_groups/${this.communityGroup.id}/community_group_users`,
        data: form
      }).then((res) => {
        if (res.data.status) {
          this.isJoin = true
          this.isjoinModalActive = false
          this.groupUsers += 1
          this.isOpenJoinPass = false
          this.toggerMyCommuButton(this.isJoin)
          commuSuccess(res.data.message)
        } else {
          this.isjoinModalActive = false
          this.isOpenJoinPass = false
          commuError(res.data.message)
        }
      })
    },
    async joinGroup (value = false) {
      const form = new FormData()
      form.append('community_group_user[organization_group_id]', this.communityGroup.id)
      await axios({
        method: 'POST',
        url: `/communities/${this.currentCommunity.community_id}/community_groups/${this.communityGroup.id}/community_group_users`,
        data: form
      }).then((res) => {
        if (res.data.status) {
          this.isJoin = true
          this.isjoinModalActive = false
          this.groupUsers += 1
          this.toggerMyCommuButton(this.isJoin)
          commuSuccess(res.data.message)
        }
      })
    },
    newTopic () {
      if (this.isJoin) {
        window.location.href = `/communities/${this.currentCommunity.community_id}/community_groups/${this.communityGroup.id}/community_group_topics/new`
      } else {
        this.isjoinModalActive = true
      }
    },
    openModalJoin () {
      this.isjoinModalActive = true
    },
    urlListUser (groupId) {
      return this.groupUsers > 0 ? `/communities/${this.currentCommunity.community_id}/community_groups/${groupId}/list_user` : '#'
    },
    redirect (url) {
      window.location.href = url
    },
    fetchPots (param) {
      this.currentPage = param
      this.fetchPosts(param)
    },
    redirectEventNew () {
      if (this.currentUser.supporter) {
        window.location.href = `/communities/${this.communityId}/managements/events/new?group_id=${this.communityGroup.id}`
      } else {
        this.modal = true
      }
    },
    async sendConfirm () {
      const form = new FormData()
      form.append('message', this.joinRequest)
      await axios({
        method: 'POST',
        url: `/communities/${this.currentCommunity.community_id}/community_groups/${this.communityGroup.id}/contact`,
        data: form
      }).then((res) => {
        if (res.data.status) {
          this.isSendDone = true
        }
      })
    },
    redirectListGroup () {
      window.location.href = `/communities/${this.currentCommunity.community_id}/community_groups`
    },
    goToEdit () {
      window.location.href = `${this.communityGroup.pathEdit}`
    },
    sendRequest () {
      this.isShowRequest = true
    },
    clickBack () {
      this.isShowRequest = false
      this.modal = false
    },
    goToAsmamaEventMeeting () {
      window.location.href = 'https://asmama.jp/event/meeting/'
    }
  }
}
</script>
<style scoped lang="sass">
  .detail
    background: #FFFFFF
    .image-group
      img
        aspect-ratio: 5 / 2
        object-fit: cover
        width: 100%
    .info-group
      padding: 20px 24px
      margin-bottom: 35px
      .title-group
        font-weight: bold
        font-size: 16px
        line-height: 20px
        text-align: justify
        color: #273D35
        width: 90%
      .count-member
        font-weight: 400
        font-size: 14px
        line-height: 14px
        color: #273D35
        margin-bottom: 24px
        a
          color: #2DBA87
        span
          color: #2DBA87
      .description
        font-weight: 300
        font-size: 15px
        line-height: 22px
        color: #273D35
        margin-bottom: 24px
      .button-join
        margin-bottom: 12px
        .join-group
          display: flex
          align-items: center
          .icon-status-join
            display: flex
            margin-right: 6px
          .status-join
            font-size: 15px
            color: #2DBA87
      .name-and-action
        display: flex
        justify-content: space-between
        align-items: center
        .option-button
          background-color: transparent
          border: none
          cursor: pointer
          padding: 0
          transform: rotate(90deg)
          .option-text
            font-size: 0.7rem
            margin-top: -5px
        /deep/
          .dropdown-menu
            min-width: 8rem
            .dropdown-content
              padding: 3px 0 3px 10px
        .option-button-horizontal
          .icon
            margin-top: 10px
            color: #2DBA87
  .list-topic
    padding: 0px 0px 24px 0px
    background: #f8f7f2
    .button-add
      margin-top: 32px
      /deep/ span
        display: flex
        width: 100%
        align-items: center
        .add-topic-icon
          display: flex
          width: unset
          width: 24px
          height: 24px
          margin-left: 4px
        p
          width: 100%
          font-weight: bold
  .detail-group
    .join-modal-active
      /deep/
        .modal-background
          background: #273D35
          opacity: 0.9
        .modal-close
          display: none
        .animation-content
          margin: 0px 52px
          width: unset
          width: 80%
          background: #FFFFFF
          .modal-card-body
            padding: 32px 50px
            text-align: center
          .modal-card
            border-radius: 20px
            .modal-card-footer
              line-height: 0
              display: inline-flex
              grid-template-columns: auto auto
              .button
                height: 48px
                border-top: 1px solid #CCCCCC
                background: #fff
                color: #2DBA87
                border-radius: 0
                font-size: 15px
                min-width: 50%
                &:focus-visible
                  border: none
                &.cancel
                  border-right: unset
              .ok
                font-weight: bold
                border-left: 1px solid #CCCCCC
  .fw-bold
    /deep/ span
      font-weight: bold
  .post-space
    width: 92%
    margin: 0 auto 20px auto
  .event-filter
    /deep/
      .modal-card
        font-size: 14px
        line-height: 24px
        display: flex
        justify-content: center
        padding: 0 52px
        .modal-card-body
          text-align: center
          border-radius: 20px
          padding: 32px 20px
          .cancel
            font-weight: bold
            color: #2DBA87
          .button-supporter
            margin-top: 16px
            margin-bottom: 24px
          .button-create
            margin-top: 18px
            margin-bottom: 28px
          .modal-close
            display: none
            .base-button
              margin-top: 14px
              margin-bottom: 22px
              button
                font-size: 14px !important
                padding: 16px 6px 16px 6px !important
      .base-button
        margin: auto auto 30px
  .join-pass
    flex-direction: column
    justify-content: flex-end
    /deep/
      .modal-background
        background: #273D35
        opacity: 0.9
      .modal-card
        height: 65vh
        border-radius: 10px 10px 0px 0px
        .card-join-pass
          p.header
            text-align: center
            font-weight: 700
            font-size: 16px
          .form-join-pass
            padding: 15px 0px
          .confirm-admin
            margin-top: 15px
            margin-bottom: 10px
            p
              text-align: center
              font-size: 15px
          .header-confirm-admin
            display: flex
            justify-content: space-between
            align-items: center
            margin-bottom: 15px
            .face-block
              width: 24px
            p
              font-size: 16px
              font-weight: bold
          .body-confirm-admin
            p
              font-size: 15px
              text-align: center
              margin-bottom: 15px
            .body-confirm
              margin-bottom: 10px
      .modal-close
        display: none
  .join-container
    display: flex
    align-items: baseline
    justify-content: space-between
  .group-statuses
    display: flex
  .ml-10px
    margin-left: 10px
  .mycommu-button
    bottom: 89px
    left: 0
    position: fixed
    width: 100%
    line-height: 48px
    padding: 0px 9px
    display: flex
    justify-content: space-between
    /deep/
      img
        max-width: 100%
        vertical-align: middle
  .send-done
    /deep/
      .animation-content
        width: 57% !important
        .modal-card-body
          padding: 32px 20px
          text-align: center
        .modal-card
          border-radius: 20px
          .modal-card-footer
            line-height: 0
            display: inline-flex
            grid-template-columns: auto auto
            .button
              height: 48px
              border-top: 1px solid #CCCCCC
              background: #fff
              color: #2DBA87
              border-radius: 0
              font-size: 15px
              min-width: 50%
              &:focus-visible
                border: none
              &.cancel
                border-right: unset
            .ok
              font-weight: bold
              border-left: 1px solid #CCCCCC
  .fw-bold
    /deep/ span
      font-weight: bold
  .post-space
    width: 92%
    margin: 0 auto 20px auto
</style>
