import imageCompression from 'browser-image-compression'

export async function compressUploadedImage (file) {
  const compOpts = {
    maxSizeMB: 3,
    maxWidthOrHeight: 1024,
    fileType: file.type
  }
  const compBlob = await imageCompression(file, compOpts)
  const newBlob = new Blob([await compBlob.arrayBuffer()], { type: file.type })
  return new File([await newBlob.arrayBuffer()], file.name, {
    type: file.type,
    lastModified: file.lastModified
  })
}
