<template>
  <div class="detail-group" >
    <section>
      <Modal v-model="isModalActive">
        <Processing/>
        <template #header>
          <Back
            title="シェア投稿"
            :show-commu="false"
            :icon-back="iconBack"
            :use-emit="true"
            @back="back"
          />
        </template>
        <div :class="loading ? 'is-processing' : ''">
          <div class="modal-description">
            <p>あなたの周りの気になる事を</p>
            <p>コミュニティのみんなに共有しましょう。</p>
          </div>
          <MyCommuBaseTextInput
            v-model="content"
            label="メッセージ"
            placeholder="投稿する本文を入力してください。"
            type="textarea"
          />

          <div class="setting-image mt-24">
            <div class="event-label">
              <label>
                <div class="primary">画像設定</div>
              </label>
              <MyCommuOptional />
            </div>
            <p class="image-note">最大4枚・20MBまで、JPG/JPEG・PNG・GIF形式対応</p>
          </div>
          <div class="mt-8 upload-image">
            <BField class="is-white file">
              <BUpload
                v-model="files"
                class="file-label file-upload"
                :multiple="true"
                :disabled="files.length === 4"
                @input="cutFiles"
                accept=".jpg, .png, .jpeg, .gif"
              >
                <span
                  class="file-cta"
                  :class="{'disabled': files.length === 4}"
                >
                  <BIcon
                    class="file-icon"
                    icon="plus"
                  />
                </span>
              </BUpload>
            </BField>
            <div
              v-for="(file, index) in files"
              :key="index"
              class="image-upload"
            >
              <img
                :src="getUrl(file)"
                class="img"
              >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="removeImage(index)"
              >
                <path
                  d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                  fill="white"
                  stroke="#273D35"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6 6L10 10"
                  stroke="#273D35"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 6L6 10"
                  stroke="#273D35"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <MyCommuBaseButton
            class="mt-40"
            text-content="投稿する"
            :disable="content === ''"
            @clickButton="createTopic"
          />
        </div>
      </Modal>
      <b-modal
        :active.sync="modal"
        has-modal-card
        :on-cancel="changeClickedTopic()"
      >
        <div class="modal-card">
          <div class="modal-card-body">
            <p class="fw-bold">
              コミュニティ内で共有しましょう！
            </p>
            <p class="fw-bold">
              誰かが助けてくれるかも
            </p>
            <div class="d-flex mt-32">
              <div class="content-left">
                <img src="../../../images/icons/icon-post.svg">
                <span class="title">投稿</span>
                <p class="text-align-start">
                  あなたの気になるもの<br>
                  や、自分の好きな場所<br>
                  等なんでも教えてくださ<br>
                  い。
                </p>
              </div>
              <div class="content-right">
                <img src="../../../images/icons/icon-share.svg">
                <span class="title">シェア</span>
                <p class="text-align-start">
                  モノの貸し借りや、空<br>
                  いた時間でのお手伝<br>
                  い、子どもの送迎など<br>
                  をお願いできます。
                </p>
              </div>
            </div>
            <div class="content-footer">
              <p>（一部を除き内容はホーム画面に投稿されます）</p>
            </div>
            <p
              class="cancel"
              @click="modal = false"
            >
              閉じる
            </p>
          </div>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import Back from '../Back'
import { mapActions, mapState } from 'vuex'
import Modal from '../Modal'
import axios from 'axios'
import { compressUploadedImage } from '../../../lib/compress_uploaded_image'

export default {
  components: { Back, Modal },
  props: {
    firstClickTopic: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modal: !this.firstClickTopic,
      isModalActive: true,
      iconBack: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<g clip-path="url(#clip0_10847_11145)">\n' +
          '<path d="M5.43018 5.42999L18.5702 18.57" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '<path d="M18.5702 5.42999L5.43018 18.57" stroke="#273D35" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '</g>\n' +
          '<defs>\n' +
          '<clipPath id="clip0_10847_11145">\n' +
          '<rect width="24" height="24" fill="white"/>\n' +
          '</clipPath>\n' +
          '</defs>\n' +
          '</svg>\n',
      files: [],
      content: ''
    }
  },
  computed: {
    ...mapState('session', ['communityId', 'currentUser']),
    ...mapState('mycommu', ['loading'])
  },
  mounted () {
    this.toggerHeader(true)
    this.toggerMyCommuFooterMenu(false)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton', ['setCurrentUser'],
      'toggerHeader',
      'toggerMyCommuFooterMenu',
      'setLoading'
    ]),
    ...mapActions('session', ['setCurrentUser']),
    changeClickedTopic () {
      if (!this.modal) {
        const params = new FormData()
        params.append('profile[clicked_topic]', true)
        axios.put('/profiles/update_clicked_topic', params).then((res) => {
          if (res.data.status) {
            this.modal = false
            this.setCurrentUser(res.data.user)
          }
        })
      }
    },
    back () {
      this.toggerHeader(false)
      this.toggerMyCommuFooterMenu(true)
      this.$emit('closeModal')
    },
    getUrl (e) {
      const url = URL.createObjectURL(e)
      URL.revokeObjectURL(e)
      return url
    },
    cutFiles () {
      if (this.files.length > 4) {
        this.files = this.files.slice(0, 4)
      }
    },
    removeImage (index) {
      this.files.splice(index, 1)
    },
    async createTopic () {
      this.setLoading(true)
      const currentPath = location.pathname
      const data = new FormData()
      const filesArray = Array.from(this.files)
      let contentKey, postPath, fileKeyBase, nextPath
      if (currentPath.includes('community_groups')) {
        const groupId = window.location.href.split('community_groups/')[1]
        contentKey = 'community_group_topic[body]'
        fileKeyBase = 'community_group_topic'
        postPath = `/communities/${this.communityId}/community_groups/${groupId}/community_group_topics`
        nextPath = `/communities/${this.communityId}/community_groups/${groupId}`
      } else {
        contentKey = 'topic[body]'
        fileKeyBase = 'topic'
        postPath = `/communities/${this.communityId}/topics`
        nextPath = '/home'
      }
      data.append(contentKey, this.content)
      for (const [i, file] of filesArray.entries()) {
        const index = 1 + i
        const fileKey = `${fileKeyBase}[image${index}]`
        const newFile = await compressUploadedImage(file)
        data.append(fileKey, newFile)
      }
      await axios.post(postPath, data).then((res) => {
        this.setLoading(false)
        window.location.href = nextPath
      })
    }
  }
}
</script>
<style scoped lang="sass">
.is-processing
  opacity: 0.5
.event-label
  margin-top: 20px
  display: flex
  justify-content: space-between
  align-content: center
  margin-bottom: 5px
.ml-20
  margin-left: 20px
.mt-40
  margin-top: 40px
.upload-image
  display: flex
  /deep/
  .file
    margin-right: 16px
    display: unset
    .file-upload
      width: 48px
      height: 48px
      box-shadow: 0px 2px 8px #f2efde
      .file-cta
        width: 100%
        height: 100%
    .label
      font-size: 14px
      cursor: pointer
    .file-upload
      .file-icon
        margin: auto
        color: #2DBA87
      .disabled
        opacity: 0.4
.image-upload
  position: relative
  border-radius: 6px
  margin-right: 16px
  .img
    width: 48px
    height: 48px
    object-fit: cover
    border-radius: 6px
    border: 1px solid #7C7C7C
  svg
    position: absolute
    left: 38px
    top: -5.5px

/deep/
  .modal-body
    background-color: #F8F7F2
    padding: 0 32px
    .modal-content
      margin: 0 !important
      padding: 0 32px
      .modal-description
        text-align: center
        margin: 40px 0
        color: #273D35
        font-size: 15px
/deep/
  .cancel
    font-weight: bold
    color: #2DBA87
  .modal-background
    background: #273D35
    opacity: 0.9
  .animation-content
    display: contents
    .modal-card
      font-size: 14px
      line-height: 24px
      display: flex
      justify-content: center
      //padding: 0 52px
      padding: 0 24px
      .modal-card-body
        text-align: center
        border-radius: 20px
        padding: 32px 20px
  .button-supporter
    margin-top: 16px
    margin-bottom: 24px
  .button-create
    margin-top: 18px
    margin-bottom: 28px
  .modal-close
    display: none
    .base-button
      margin-top: 14px
      margin-bottom: 22px
      button
        font-size: 14px !important
        padding: 16px 6px 16px 6px !important

.d-flex
  display: flex
.mt-32
  margin-top: 32px
.title
  font-weight: bold !important
  font-size: 15px
  margin: 16px 0
.fw-bold
  font-weight: bold !important
.text-align-start
  display: flex
  justify-content: center
  text-align: start !important
.content-left
  display: flex
  flex-direction: column
  margin-right: 8px
  width: 50%
  color: #273D35
  font-size: 12px
  img
    height: 38px
.content-right
  display: flex
  flex-direction: column
  margin-left: 8px
  width: 50%
  color: #273D35
  font-size: 12px
  img
    height: 38px
.content-footer
  margin-top: 24px
  font-size: 10px
.cancel
  font-weight: bold
  color: #2DBA87
  margin-top: 28px
.image-note
  font-size: 12px
  color: #2DBA87
  margin: 8px 0 16px
</style>
