<template>
  <div>
    <Back title="予約者の情報編集" :showCommu="false" :showCancel="true"/>
    <div class="space">
      <form>
        <HiddenHeader/>
        <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
        <MyCommuHiddenFooterMenu :is-my-commu-domain="true"/>

        <div class="mb-24px">
          <MyCommuBaseSelectInput
            v-model="scheduleSelected"
            label="予約する回"
            :options="scheduleTime"
            name="ticket[schedule_id]"
            :is-require="true"
          />
        </div>
        <div class="columns is-mobile mb-24px">
          <div class="column">
            <MyCommuBaseTextInput
              id="profile_last_name"
              v-model="lastName"
              label="お名前"
              name="ticket_profile[last_name]"
              placeholder="性"
            />
          </div>
          <div class="column">
            <MyCommuBaseTextInput
              id="profile_first_name"
              v-model="firstName"
              label=""
              :is-require="true"
              name="ticket_profile[first_name]"
              placeholder="名"
            />
          </div>
        </div>
        <div class="columns is-mobile mb-24px">
          <div class="column">
            <MyCommuBaseTextInput
              id="profile_last_name_kana"
              v-model="lastNameKana"
              label="セイ"
              name="ticket_profile[last_name_kana]"
              placeholder="セイ"
            />
          </div>
          <div class="column">
            <MyCommuBaseTextInput
              id="profile_first_name_kana"
              v-model="firstNameKana"
              label=""
              :is-require="true"
              name="ticket_profile[first_name_kana]"
              placeholder="メイ"
            />
          </div>
        </div>

        <div class="columns is-mobile mb-24px">
          <div class="column is-fullwidth select-prefecture">
            <MyCommuBaseSelectInput
              id="profile_sex"
              v-model="sex"
              label="性別"
              :is-require="true"
              name="ticket_profile[sex]"
              placeholder="選択する"
              :options="sexOptions"
            />
          </div>
          <div class="column" />
        </div>
        <div class="columns is-mobile">
          <div class="column">
            <BField
              :message="zipCodeErrorMessage"
              :type="zipCodeStatus"
              class="is-mb-0 flex-space-between"
              label="郵便番号"
            >
              <MyCommuRequire/>
            </BField>
            <BInput
              v-model="dirtyZipCode"
              name="ticket_profile[zip_code]"
              placeholder="1000013"
              class="is-fullwidth"
              customClass=" input-green"
            />
          </div>
          <div class="column handle-zip-code">
            <BButton
              class="is-fullwidth is-mt-1 bg-white"
              type='is-success'
              outlined
              @click="handleZipCodeKeypress"
            >
              住所検索
            </BButton>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column is-fullwidth select-prefecture">
            <BField
              label="都道府県"
              label-for="profile_prefecture_"
              class="is-mb-0 flex-space-between"
            >
              <MyCommuRequire/>
            </BField>
            <BSelect
              id="profile_prefecture_"
              v-model="dirtyPrefecture"
              name="ticket_profile[prefecture]"
              class="js-geocoding-region is-fullwidth input-green"
            >
              <option value="" disabled selected hidden>選択する</option>
              <option
                v-for="opt in prefectureOptions"
                :key="opt"
                :value="opt"
                v-text="opt"
              />
            </BSelect>
          </div>
          <div class="column" />
        </div>
        <div class="mb-24px">
          <BField
            class="label-fs-14px is-mb-0 flex-space-between"
            label="市区町村"
            label-for="profile_address1_"
          >
            <MyCommuRequire/>
          </BField>
          <BInput
            id="profile_address1_"
            v-model="dirtyAddress1"
            name="ticket_profile[address1]"
            placeholder="横浜市中区山下町"
            class="js-geocoding-address1"
            customClass="input-green"
          />
        </div>
        <div class="mb-24px">
          <MyCommuBaseTextInput
            :is-require="true"
            id="profile_mobile_phone"
            v-model="mobilePhone"
            label="携帯電話番号"
            name="ticket_profile[mobile_phone]"
            placeholder="080-1234-5678"
          />
        </div>
        <div class="mb-24px">
          <MyCommuBaseSelectInput
            v-model="joinCountModel"
            label="AsMama交流イベントへの参加は何回目ですか？"
            v-bind="joinCount"
            name="ticket[join_count]"
          />
        </div>
        <div class="mb-24px">
          <MyCommuBaseTextInput
            v-model="inviterNameModel"
            v-bind="inviterName"
            name="ticket[inviter_name]"
          />
        </div>
        <div class="mb-24px">
          <MyCommuBaseSelectInput
            v-model="opportunitySelected"
            v-bind="opportunity"
            :options="knowToEvent"
            name="ticket[opportunity]"
            :is-require="true"
          />
        </div>
        <div class="mb-24px">
          <MyCommuBaseTextInput
            v-model="suggesionModel"
            v-bind="suggesion"
            type="textarea"
            name="ticket[suggesion]"
          />
        </div>

        <p class="child-title">お子さまとご一緒に交流イベントに参加する場合のみ入力ください</p>

        <div class="child-wrap">
          <div v-for="(item, key) in children" :key="key" class="mb-24px">
            <MyCommuChildTicket
              :title="'子どもの情報' + (extractNumberFromKey(key) + 1)"
              :child="item"
              :has-child="hasChild"
              :open="item.id !== null || item.open"
            />
            <BButton
              v-if="item.id == null"
              class="is-fullwidth is-mt-4 bg-white child-button"
              type='is-success'
              outlined
              @click="removeChild(key)"
            >
              子どもの情報{{ extractNumberFromKey(key) + 1 }}を削除
            </BButton>
          </div>
          <BButton
            class="is-fullwidth bg-white child-button"
            type='is-success'
            outlined
            @click="addChild()"
          >
            子どもを追加
          </BButton>
        </div>

        <div class="mt-40px">
          <MyCommuBaseButton
            :disable="!canSubmit"
            text-content="予約する"
            @clickButton="create()"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Back from '../../components/my-commu/Back'
import axios from 'axios'
import { mapState } from 'vuex'
import { findAddressFromPostal } from '../../lib/auto_input_address'
import { commuError } from '../../lib/app-toast'

export default {
  components: {
    Back
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    profile: {
      type: Object,
      require: true
    },
    // eslint-disable-next-line vue/require-default-prop
    childProfiles: {
      type: String,
      require: true
    },
    prefecture: {
      type: String,
      default: ''
    },
    address1: {
      type: String,
      default: ''
    },
    sexOptions: {
      type: Array,
      default: () => [
        ['女性', 'female'],
        ['男性', 'male'],
        ['無回答', 'no_answer']
      ]
    },
    zipCode: {
      type: String,
      default: ''
    },
    prefectureOptions: {
      type: Array,
      required: true
    },
    joinCount: {
      type: Object,
      default: () => {}
    },
    opportunity: {
      type: Object,
      default: () => {}
    },
    suggesion: {
      type: Object,
      default: () => {}
    },
    inviterName: {
      type: Object,
      default: () => {}
    },
    knowToEvent: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: ''
    },
    hasChild: {
      type: Boolean,
      default: false
    },
    scheduleTime: {
      type: Array,
      default: () => []
    }
  },
  data () {
    const childProfiles = JSON.parse(this.childProfiles)
    const defaultChildProfile = {
      id: null,
      joined_event: false,
      first_name: '',
      first_name_kana: '',
      sex: '',
      date_of_birth: null,
      discharge_step: '',
      have_allergy: '',
      egg_allergy: false,
      milk_allergy: false,
      wheat_allergy: false,
      childTicket: false,
      allergy_description: '',
      day_care_experience: '',
      open: false
    }

    const children = {}
    for (let i = 1; i <= childProfiles.length; i++) {
      children[`child${i}`] = { ...defaultChildProfile }

      if (childProfiles[i - 1]) {
        children[`child${i}`].id = childProfiles[i - 1].id || ''
        children[`child${i}`].first_name = childProfiles[i - 1].first_name || ''
        children[`child${i}`].first_name_kana = childProfiles[i - 1].first_name_kana || ''
        children[`child${i}`].sex = childProfiles[i - 1].sex || ''
        children[`child${i}`].date_of_birth = childProfiles[i - 1].date_of_birth || null
      }
    }

    return {
      firstName: this.profile.first_name,
      lastName: this.profile.last_name,
      firstNameKana: this.profile.first_name_kana,
      lastNameKana: this.profile.last_name_kana,
      sex: this.profile.sex,
      mobilePhone: this.profile.mobile_phone,
      dirtyZipCode: this.zipCode,
      dirtyPrefecture: this.prefecture,
      dirtyAddress1: this.address1,
      zipCodeErrorMessage: '',
      opportunitySelected: '',
      inviterNameModel: '',
      suggesionModel: '',
      joinCountModel: '',
      scheduleSelected: this.scheduleTime[0] ? this.scheduleTime[0][1] : [],
      children: children,
      childCounter: childProfiles.length + 1
    }
  },
  computed: {
    ...mapState('session', ['communityId']),
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    ...mapState('mycommu', ['hiddenHeader']),
    canSubmit () {
      return this.opportunitySelected !== '' && this.mobilePhone !== '' && this.dirtyAddress1 !== '' &&
             this.dirtyPrefecture !== '' && this.dirtyZipCode !== '' && this.sex !== '' && this.firstNameKana !== '' &&
             this.lastNameKana !== '' && this.firstName !== '' && this.lastName && this.scheduleSelected !== ''
    },
    zipCodeStatus () {
      return this.zipCodeErrorMessage.length > 0 ? 'is-danger' : null
    }
  },
  mounted () {
    this.unsetMargin.mainContainer = true
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.unsetMargin.mainContainer = true
    this.unsetMargin.appMain = true
  },
  methods: {
    create () {
      const formData = new FormData()
      formData.append('ticket[ticket_profile_attributes][last_name]', this.lastName)
      formData.append('ticket[ticket_profile_attributes][last_name_kana]', this.lastNameKana)
      formData.append('ticket[ticket_profile_attributes][first_name]', this.firstName)
      formData.append('ticket[ticket_profile_attributes][first_name_kana]', this.firstNameKana)
      formData.append('ticket[ticket_profile_attributes][sex]', this.sex)
      formData.append('ticket[ticket_profile_attributes][zip_code]', this.dirtyZipCode)
      formData.append('ticket[ticket_profile_attributes][prefecture]', this.dirtyPrefecture)
      formData.append('ticket[ticket_profile_attributes][address1]', this.dirtyAddress1)
      formData.append('ticket[ticket_profile_attributes][mobile_phone]', this.mobilePhone)
      formData.append('ticket[schedule_id]', this.scheduleSelected)
      formData.append('ticket[join_count]', this.joinCountModel)
      formData.append('ticket[inviter_name]', this.inviterNameModel)
      formData.append('ticket[opportunity]', this.opportunitySelected)
      formData.append('ticket[suggesion]', this.suggesionModel)
      if (this.checFreeText1) {
        formData.append('ticket[free_text1]', this.free_text1)
      }
      if (this.checFreeText2) {
        formData.append('ticket[free_text2]', this.free_text2)
      }
      if (this.checFreeText3) {
        formData.append('ticket[free_text3]', this.free_text3)
      }

      Object.values(this.children).forEach((child, index) => {
        if (!this.checkChild(child)) {
          formData.append(`child_profile[child_profiles_attributes][${index}][accompany]`, child.joined_event)
          if (child.id) {
            formData.append(`child_profile[child_profiles_attributes][${index}][id]`, child.id)
          }

          formData.append(`ticket[ticket_child_profiles_attributes][${index}][accompany]`, child.joined_event)
          formData.append(`ticket[ticket_child_profiles_attributes][${index}][first_name]`, child.first_name)
          formData.append(`ticket[ticket_child_profiles_attributes][${index}][first_name_kana]`, child.first_name_kana)
          formData.append(`ticket[ticket_child_profiles_attributes][${index}][sex]`, child.sex)
          formData.append(`ticket[ticket_child_profiles_attributes][${index}][date_of_birth]`, child.date_of_birth)
          formData.append(`ticket[ticket_child_profiles_attributes][${index}][discharge_step]`, child.discharge_step)
          formData.append(`ticket[ticket_child_profiles_attributes][${index}][have_allergy]`, child.have_allergy)
          formData.append(`ticket[ticket_child_profiles_attributes][${index}][egg_allergy]`, child.egg_allergy)
          formData.append(`ticket[ticket_child_profiles_attributes][${index}][milk_allergy]`, child.milk_allergy)
          formData.append(`ticket[ticket_child_profiles_attributes][${index}][wheat_allergy]`, child.wheat_allergy)
          formData.append(`ticket[ticket_child_profiles_attributes][${index}][allergy_description]`, child.allergy_description)
          formData.append(`ticket[ticket_child_profiles_attributes][${index}][day_care_experience]`, child.day_care_experience)
        }
      })
      axios.post(`${this.path}`, formData).then((res) => {
        if (res.data.status === 'success') {
          window.location.href = res.data.redirectPath
        } else {
          commuError(res.data.message)
        }
      })
    },
    checkChild (child) {
      return child.joined_event === false &&
          child.first_name === '' &&
          child.first_name_kana === '' &&
          child.sex === '' &&
          child.discharge_step === '' &&
          child.have_allergy === '' &&
          child.egg_allergy === false &&
          child.milk_allergy === false &&
          child.wheat_allergy === false &&
          child.allergy_description === '' &&
          child.day_care_experience === ''
    },
    async fillAddressByZipCode (zipCode) {
      const { address, error } = await findAddressFromPostal(zipCode)
      if (address) {
        this.dirtyPrefecture = address.region
        this.dirtyAddress1 = address.locality + address.street
        this.zipCodeErrorMessage = ''
      } else {
        this.dirtyPrefecture = ''
        this.dirtyAddress1 = ''
        this.zipCodeErrorMessage = error
      }
    },
    handleZipCodeKeypress (ev) {
      this.fillAddressByZipCode(this.dirtyZipCode)
    },
    addChild () {
      this.$set(this.children, `child${this.childCounter}`, {
        id: null,
        first_name: '',
        first_name_kana: '',
        sex: '',
        date_of_birth: '',
        joined_event: '',
        discharge_step: '',
        have_allergy: '',
        egg_allergy: false,
        milk_allergy: false,
        wheat_allergy: false,
        allergy_description: '',
        day_care_experience: '',
        open: true
      })
      this.childCounter++
    },
    removeChild (key) {
      this.$delete(this.children, key)
      this.childCounter--
    },
    extractNumberFromKey (key) {
      const match = key.match(/\d+/)
      return match ? parseInt(match[0], 10) - 1 : 0
    }
  }
}
</script>

<style scoped lang="sass">
.space
  margin-top: 40px
  padding: 0 32px

  .profile-avatar
    width: 64px
    height: 64px
    object-fit: cover
    margin-right: 24px
    border-radius: 50%
    flex-shrink: 0

  .edit-avatar
    display: flex
    align-items: center

  .mb-60px
    margin-bottom: 60px

  .mb-24px
    margin-bottom: 24px

  .mt-14px
    margin-top: 14px

  .label-fs-14px
    /deep/
      label
        font-size: 14px

.dark-gray-color
  /deep/
  span
    select
      color: #AAAAAA

.column
  padding-bottom: unset

  /deep/ label
    font-weight: 300
    font-size: 14px
    line-height: 14px
    margin-bottom: 8px

.sign-up-address-input-address__form
  border-radius: 6px

  /deep/ label
    font-weight: 300
    font-size: 14px
    line-height: 14px
    margin-bottom: 8px

  /deep/
  .field
    margin-bottom: 40px

.select-prefecture
  /deep/ span
    width: 100%

  /deep/ select
    width: 100%
    border-radius: 6px
    caret-color: #2DBA87

    &:focus
      border: 1px solid #2DBA87
      border-radius: 6px
      box-shadow: 0 0 0 0

/deep/
.reject-button
  color: #EE5353 !important
  padding: 9px 0 !important

  &:hover
    background: #FFEEF0 !important

  .birthday-picker
    span
      &:after
        margin-top: -0.2875em !important
        height: 0.625em !important
        width: 0.625em !important
        right: 0.725em !important
        border-width: 3px !important

.d-none
  display: none

.mb-8
  margin-bottom: 8px

.mr-8
  margin-right: 8px

.mt-40px
  margin-top: 40px

.optional
  align-items: center
  justify-content: space-between
.handle-zip-code
  margin-top: 17px
  /deep/ > button.is-outlined
    border: unset
    box-shadow: 0 2px 8px #F2EFDE
    border-radius: 6px
    background: white !important
    font-weight: bold
    &:hover
      background-color: #D5F1E7
      color: #2DBA87
/deep/ .input-green
  border-radius: 6px
  caret-color: #2DBA87
  &:focus
    border: 1px solid #2DBA87
    border-radius: 6px
    box-shadow: 0 0 0 0
/deep/
  .border-radius-6
    border-radius: 6px
  input
    height: 48px
    box-shadow: none
    font-family: "Hiragino Kaku Gothic ProN"
  .select
    height: 48px !important
    select
      padding-right: 0.5rem !important
      height: 48px
      font-family: "Hiragino Kaku Gothic ProN"
  button
    height: 48px
    font-family: "Hiragino Kaku Gothic ProN"
.child-title
  font-size: 14px
  margin-bottom: 8px
.child-wrap
  .button.child-button
    border: unset
    box-shadow: 0 2px 8px #F2EFDE
    border-radius: 6px
    background: white !important
    font-weight: bold
    &:hover
      background-color: #D5F1E7
      color: #2DBA87
    &:focus
      background-color: #D5F1E7
      color: #2DBA87
.flex-space-between
  display: flex
  justify-content: space-between
</style>
