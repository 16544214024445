<template>
  <div class="">
    <MyCommuHiddenButtonFooter :is-my-commu-domain="true"/>
    <MyCommuHiddenFooterMenu :is-my-commu-domain="true"/>
    <p class="guide">マイコミュでは、コミュニティごとに個別の自己紹介を登録できるようになっています。コミュニティにあわせた自己紹介をしてみましょう。</p>
    <label class="sign-up-label mt-24px" v-if="avatarProps">
      <div class="primary">プロフィール写真</div>
      <MyCommuRequire/>
    </label>
    <p class="image-note">JPG/JPEG・PNG・GIF形式、最大10MBまで</p>

    <div class="is-mt-1 avatar-flex" v-if="avatarProps">
      <BUpload v-model="file" class="file-label file-upload" :name="avatarProps.name" accept=".jpg, .png, .jpeg, .gif">
        <div class="display-image" v-if="file || avatarProps.value !== null">
          <img :src="displayFile" />
        </div>
        <span v-else class="file-cta">
          未設定
        </span>
        <BButton type='is-success' outlined>
          写真を選択する
        </BButton>
      </BUpload>
    </div>
    <div v-if="!file && changeAvatar" class="invalid-image">JPG/JPEG・PNG・GIF形式</div>

    <BaseSelectNickname :nickname-selected="nicknameSelected" :nickname="nickname" :is-my-commu-domain="true" />

    <label class="sign-up-label mt-24px">
      <div class="primary">出身地</div>
      <MyCommuRequire/>
    </label>
    <div class="is-mt-1">
      <BField :label="placeOfBirthProps.label" :message="placeOfBirthProps.errors[0]"
        :type="{ 'is-danger': this.placeOfBirthProps.errors.length > 0 }" :label-for="placeOfBirthProps.id">
        <BSelect v-model="placeOfBirthModel" :name="placeOfBirthProps.name"
          :class="{'is-mbfs-small': placeOfBirthProps.isMobileSmall}" class="select-tag" placeholder="選択する">
          <option v-for="opt in placeOfBirthProps.collection" :key="opt[1]" :value="opt[1]" v-text="opt[0]" />
        </BSelect>
      </BField>
    </div>

    <label class="sign-up-label mt-24px">
      <div class="primary">趣味・特技</div>
    </label>
    <div class="is-mt-1">
      <BaseTextInput v-bind="hobbyAndSkillsProps" :is-my-commu-domain="true" placeholder="カメラ" />
    </div>

    <label class="sign-up-label mt-24px">
      <div class="primary">職業</div>
    </label>
    <div class="is-mt-1">
      <BaseSelectInput placeholder="選択する" v-bind="professionProps" :is-my-commu-domain="true" />
    </div>

    <label class="sign-up-label mt-24px">
      <div class="primary">自己紹介</div>
    </label>
    <div class="is-mt-1">
      <BaseTextInput v-bind="introductionProps" :is-my-commu-domain="true" />
    </div>

    <label class="sign-up-label mt-24px">
      <div class="primary">興味あること（複数選択可）</div>
    </label>

    <div class="is-flex" style="flex-wrap: wrap; margin-top: 16px">
      <div class="is-flex magin-bottom-8">
        <BaseCheckboxButton class="magin-right-8" v-bind="isInterestedCooking" />
        <BaseCheckboxButton v-bind="isInterestedSport" />
      </div>
      <div class="is-flex magin-bottom-8">
        <BaseCheckboxButton class="magin-right-8 " v-bind="isInterestedFashion" />
        <BaseCheckboxButton v-bind="isInterestedOutdoor" />
      </div>
      <div class="is-flex magin-bottom-8 w-100">
        <BaseCheckboxButton class="magin-right-8 " v-bind="isInterestedBookAndManga" />
        <BaseCheckboxButton class="magin-right-8 " v-bind="isInterestedParenting" />
        <BaseCheckboxButton v-bind="isInterestedPet" />
      </div>
      <div class="is-flex magin-bottom-8 w-100">
        <BaseCheckboxButton class="magin-right-8 " v-bind="isInterestedGame" />
        <BaseCheckboxButton class="magin-right-8 " v-bind="isInterestedMovieAndArt" />
        <BaseCheckboxButton v-bind="isInterestedArea" />
      </div>
      <div class="is-flex magin-bottom-8 ">
        <BaseCheckboxButton class="magin-right-8" v-bind="isInterestedBusiness" />
        <BaseCheckboxButton class="magin-right-8" v-bind="isInterestedTrip" />
        <BaseCheckboxButton v-bind="isInterestedMusic" />
      </div>
    </div>
    <BaseSelectInterestedFreeTag :selected="freeTagSelected" :tag="freeTagTag" :is-my-commu-domain="true" />

    <div class="columns is-mobile is-centered is-mt-5 is-mb-5">
      <div class="column">
        <BButton type='submit' @click='submit' :disabled="checkInput" class="is-fullwidth is-success btn-submit">
          アカウントを登録する
        </BButton>
      </div>
    </div>

  </div>
</template>

<script>
import BaseSelectInput from '../BaseSelectInput'
import BaseTextInput from '../BaseTextInput'
import BaseCheckboxButton from '../BaseCheckboxButton'
import BaseSelectInterestedFreeTag from '../BaseSelectInterestedFreeTag'
import BaseSelectNickname from '../BaseSelectNickname'
import { mapActions, mapState } from 'vuex'
import Input from './comments/Input'
import HiddenFooterMenu from "./HiddenFooterMenu";
import { compressUploadedImage } from '../../lib/compress_uploaded_image'

export default {
  components: {
    HiddenFooterMenu,
    Input, BaseSelectInput, BaseTextInput, BaseCheckboxButton, BaseSelectInterestedFreeTag, BaseSelectNickname },
  props: {
    placeOfBirthProps: {
      type: Object,
      required: true
    },
    hobbyAndSkillsProps: {
      type: Object,
      required: true
    },
    professionProps: {
      type: Object,
      required: true
    },
    introductionProps: {
      type: Object,
      required: true
    },
    isInterestedParenting: {
      type: Object,
      required: true
    },
    isInterestedPet: {
      type: Object,
      required: true
    },
    isInterestedSport: {
      type: Object,
      required: true
    },
    isInterestedCooking: {
      type: Object,
      required: true
    },
    isInterestedFashion: {
      type: Object,
      required: true
    },
    isInterestedOutdoor: {
      type: Object,
      required: true
    },
    isInterestedBookAndManga: {
      type: Object,
      required: true
    },
    isInterestedGame: {
      type: Object,
      required: true
    },
    isInterestedMovieAndArt: {
      type: Object,
      required: true
    },
    isInterestedArea: {
      type: Object,
      required: true
    },
    isInterestedBusiness: {
      type: Object,
      required: true
    },
    isInterestedTrip: {
      type: Object,
      required: true
    },
    isInterestedMusic: {
      type: Object,
      required: true
    },
    freeTagSelected: {
      type: Boolean,
      default: false
    },
    freeTagTag: {
      type: String,
      default: ''
    },
    nicknameSelected: {
      type: Boolean
    },
    nickname: {
      type: String,
      default: ''
    },
    avatarProps: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      placeOfBirthModel: this.placeOfBirthProps.value,
      file: null,
      changeAvatar: false
    }
  },
  computed: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader'
    ]),
    ...mapState('space', ['unsetMargin', 'unsetPadding']),
    checkInput () {
      // return this.placeOfBirthModel === null || this.file === null
      return this.placeOfBirthModel === null || (this.file === null && this.avatarProps.value === null)
    },
    // eslint-disable-next-line vue/return-in-computed-property
    displayFile () {
      return (this.file) ? URL.createObjectURL(this.file) : this.avatarProps.value
    },
    uploadFile () {
      return this.file
    }
  },
  methods: {
    async submit () {
      const form = document.querySelector('form')
      const formData = new FormData(form)
      const file = formData.get('avatar')
      const compressed = await compressUploadedImage(file)
      formData.set('avatar', compressed)
      form.submit()
    },
    options () {
      const opts = this.collection.map((e) => {
        const [label, value] = Array.isArray(e) ? e : [e, e]
        return { label, value }
      })
      if (this.includeBlank) {
        return [{ label: '', value: '' }].concat(opts)
      }
      return opts
    }
  },
  watch: {
    uploadFile () {
      this.changeAvatar = true
    }
  },
  created () {
    this.unsetMargin.mainContainer = true
    this.unsetMargin.appMain = true
    this.unsetPadding.mainContainer = true
  }
}
</script>
<style lang="sass" scoped>
@import '~stylesheets/resources'
/deep/
  @import '~stylesheets/components/required-label'
  @import '~stylesheets/components/published-label'
/deep/ .select:not(.is-multiple):not(.is-loading)::after
  border-width: 2px
  height: 0.425em
  width: 0.425em
  margin-top: -0.2875em
/deep/
+mobile
  .is-mbfs-small
    font-size: 14px
  .select select,
  .select select:not([multiple])
    padding-right: 1.5em
  .select:not(.is-multiple):not(.is-loading)::after
    right: 0.75em
.select-tag
  /deep/ span
    width: 100%
  /deep/ select
    width: 100%
    border-radius: 6px
    caret-color: #2DBA87
    &:focus
      border: 1px solid #2DBA87
      border-radius: 6px
      box-shadow: 0 0 0 0

/deep/
  input
    height: 48px
  .select
    height: 48px !important
    select
      height: 48px
  button
    height: 48px
.guide
  margin-bottom: 30px
/deep/ .switch
  cursor: default
.optional
  background-color: #FFFFFF
  font-weight: bold
.is-success
  /deep/ span
    font-weight: bold
.magin-right-8
  margin-right: 8px
.magin-bottom-8
  margin-bottom: 8px
.flex-bettwen
  justify-content: space-between
.w-100
  width: 100%
.file-cta
  width: 64px
  height: 64px
  border-radius: 50%
  font-size: 14px
  padding-left: 0.7em
  background: #C4C4C4
  color: white
  font-weight: bold
  border-color: unset

.avatar-flex
  display: flex
  align-items: center

  .button
    height: 32px
    padding: 9px 8px
    margin-left: 24px
    background-color: #FFFFFF !important
    border: unset
    box-shadow: 0 2px 8px #F2EFDE
    border-radius: 6px
    font-weight: bold
    font-size: 14px
    line-height: 14px
    text-align: center
    color: #2DBA87
    font-family: "Hiragino Kaku Gothic ProN"

  .display-image
    img
      width: 64px
      height: 64px
      border-radius: 50%
      object-fit: cover
      flex-shrink: 0
.file-upload
  display: flex
  align-items: center
/deep/
  button.btn-submit
    border-radius: 6px
  button.btn-submit, select, .input-my-commu > input, .input-my-commu > textarea
    font-family: 'Hiragino Kaku Gothic ProN' !important
    box-shadow: unset
.mt-24px
  margin-top: 24px
.image-note
  font-size: 12px
  color: #2DBA87
.invalid-image
  font-size: 14px
  color: red
  font-weight: 600
  margin-bottom: 16px
</style>
