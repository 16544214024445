<template>
  <div class="content-share-posts">
    <div class="description">
      <span>「譲るよ」内容入力</span>
    </div>
    <label class="event-label mr-16">
      <div class="primary">画像（最大4枚）</div>
    </label>
    <div class="margin-top-8 upload-image">
      <BField class="is-white file margin-right-16">
        <BUpload v-model="images" @input="cutFiles" class="file-label file-upload" :multiple="true" :disabled="images.length === 4" accept=".jpg, .png, .webp, .jpeg, .gif">
          <span class="file-cta" :class="{'disabled': images.length === 4}" >
            <BIcon class="file-icon" icon="plus"></BIcon>
          </span>
        </BUpload>
      </BField>
      <div
        v-for="(file, index) in images"
        :key="index"
        class="image-upload"
      >
        <div>
          <img :src="displayFile(file)" class="img">
          <svg @click="removeImage(index)" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" fill="white" stroke="#273D35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 6L10 10" stroke="#273D35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 6L6 10" stroke="#273D35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>

    <div class="post-title">
      <MyCommuBaseTextInput
        id="title"
        v-model="title"
        label="モノの名前"
        name="title"
        placeholder="掃除機"
        :is-require="true"
      />
    </div>

    <div class="columns is-mobile is-centered">
      <div class="column padding-bottom-0">
        <MyCommuBaseTextInput
          id="body"
          v-model="body"
          label="メッセージ"
          name="body"
          placeholder="●●●をもう使用しないので、家まで来ていただければ少し古いですがお譲りいたします。"
          type="textarea"
          :is-require="true"
        />
      </div>
    </div>

    <div class="columns is-mobile is-centered">
      <div class="column ">
        <label>受け渡し場所</label>
        <br>
        <label class="is-orange">できるだけ公共スポットでの受け渡しがオススメ！</label>
        <MyCommuBaseTextInput
          id="place"
          v-model="place"
          label=""
          name="place"
          placeholder="具体的な場所"
        />
      </div>
    </div>

    <div class="columns is-mobile is-centered">
      <div class="column padding-bottom-0">
        <div class="flex-between">
          <label>希望謝礼額 ※無償の場合は0と入力ください</label>
          <p>必須</p>
        </div>
        <div class="input-green entry-fee">
          <input v-model="reward" placeholder="500" step="1" min="0" type="number" class="w-50" @keydown="onKeyDown">
          <div class="yen">円</div>
        </div>
      </div>
    </div>

    <div class="columns is-mobile is-centered margin-top-32">
      <div class="column line-black padding-bottom-0">
      </div>
    </div>

    <div class="columns is-mobile is-centered column-submit">
      <div class="column">
        <BButton type='submit' @click='componentSubmit' :disabled="checkDisabled" class="is-fullwidth is-success btn-submit is-medium">
          確認する
        </BButton>
      </div>
    </div>
  </div>
</template>

<script>
import { compressUploadedImage } from '../../../lib/compress_uploaded_image';

export default {
  props: {
    submit: {
      type: Function,
      required: true
    },
    objects: {
      type: Object,
      required: true
    }
  },
  data () {
    return (this.objects.type === 'givings') ?
      {
        type: 'givings',
        images: this.objects.images,
        title: this.objects.title,
        body: this.objects.body,
        reward: this.objects.reward,
        place: this.objects.place
      } :
      {
        type: 'givings',
        images: [],
        title: null,
        body: null,
        place: null,
        reward: null
      }
  },
  computed: {
    checkDisabled () {
      const rewardNumber = /^([1-9][0-9]*|0)$/
      return this.isEmpty(this.title) || this.isEmpty(this.body) || !rewardNumber.test(this.reward)
    }
  },
  methods: {
    onKeyDown (event) {
      if (['+', '-', 'e', '.'].includes(event.key)) {
        event.preventDefault()
      }
    },
    displayFile (file) {
      if (file.url !== undefined) {
        return file.url
      }
      const url = URL.createObjectURL(file)
      URL.revokeObjectURL(file)
      return url
    },
    async componentSubmit () {
      if (this.place !== null && this.place.trim().length === 0) {
        this.place = null
      }

      this.submit({
        type: 'givings',
        images: await Promise.all(this.images.map(image => compressUploadedImage(image))),
        title: this.title,
        body: this.body,
        place: this.place,
        reward: this.reward
      })
    },
    valueScheduledDate (param) {
      this.date = param
    },
    cutFiles (e) {
      this.images = this.images.slice(0, 4)
    },
    getUrl (e) {
      const url = URL.createObjectURL(e)
      return url
    },
    removeImage (index) {
      this.images.splice(index, 1)
    },
    isEmpty (value) {
      if (value !== null && value.trim().length === 0) {
        return true
      }
      return (value == null || value === '')
    }
  }
}
</script>

<style scoped lang="sass">
.container-share-posts
  font-family: "Hiragino Kaku Gothic ProN"
  padding: 0px 32px
  color: #424242

  .description
    padding-top: 40px
    padding-bottom: 30px
    text-align: center
    span
      font-weight: 600
      font-size: 17px
.switch-custom
  display: flex
  justify-content: right
  align-items: center
.line-black
  border-top: 1px solid #CCCCCC

.content-share-posts
  .description
    padding-bottom: 8px
    padding-top: 20px
    text-align: center
    span
      font-weight: 600
      font-size: 17px
label
  color: #273D35
  font-weight: 400
  font-size: 14px

.file-cta
  height: 48px
  color: #2DBA87 !important
  .icon
    font-size: 18px
    padding: 0
    margin: 0

.columns
  margin-top: 10px
  margin-bottom: 0px !important
  .flex-between
    display: flex
    justify-content: space-between
    align-items: center
    p
      font-size: 10px
      border: 1px solid #EE5353
      color: #EE5353
      background: #FFFFFF
      padding: 2px
      font-weight: 700
      font-style: normal
      line-height: 9px
.post-title
  margin-top: 24px
.is-orange
  color: #F78F54
  font-weight: 700
  font-size: 12px

.switch-columns
  margin-top: 0px
.condition-columns
  span
    font-weight: 600
    font-size: 12px
.column-accep
  .column
    display: flex
    span
      margin-left: 10px
.container-share-preview
  color: #273D35
  padding: 0px 32px
  .description
    display: flex
    justify-content: center
    margin-top: 40px
    font-weight: 300
    font-size: 15px
    margin-bottom: 25px

  .container-type--images
    margin-top: 8px
    .display-image
      display: flex
      img
        box-shadow: 0px 2px 8px #F2EFDE
        border: 1px solid #7C7C7C
        width: 48px
        height: 48px
        border-radius: 6px
        margin-right: 16px
  .container-share-preview--content
    display: flex
    padding-top: 24px
    flex-direction: column

    &.container-button
      margin-top: 100px
  .container-share-preview--title
    font-weight: 600
    font-size: 14px
  .is-halfwidth
    width: 50%
    margin-right: 10px
  .button
    color: #2DBA87
    font-weight: 700
    font-size: 15px
    height: 48px
    &.is-success
      color: #ffffff
.modal-card-body
  //display: flex
  //justify-content: center
  //align-items: center
  //flex-direction: column
  color: #273D35
  font-size: 15px
  border-radius: 20px
  text-align: center
  padding: 40px 16px
  width: 230px
  img
    margin-bottom: 20px
    height: 66px
    width: 66px

.upload-images
  .field
    flex-direction: column
    display: flex

    .display-image
      display: flex
      img
        box-shadow: 0px 2px 8px #F2EFDE
        border: 1px solid #7C7C7C
        width: 48px
        height: 48px
        border-radius: 6px
        margin-right: 16px

.upload-image
  display: flex
  .image-upload
    position: relative
    border-radius: 6px
    margin-right: 16px
    .img
      width: 48px
      height: 48px
      object-fit: cover
      border-radius: 6px
      border: 1px solid #7C7C7C
    svg
      position: absolute
      left: 38px
      top: -5.5px

.margin-right-16
  margin-right: 16px
.margin-top-8
  margin-top: 8px
.padding-bottom-0
  padding-bottom: 0px
.margin-top-32
  margin-top: 32px

.event-label,.datepicker-event
  font-weight: 300
  font-size: 14px
  line-height: 14px
  color: #273D35
  display: flex
  justify-content: space-between
  align-items: center
.switch
  margin-left: 10px
.is-medium
  &.submit
    padding-top: 16px
    padding-bottom: 17px
    font-size: 15px
    font-weight: 600
    height: 48px
.input-green
  margin-top: 8px
  input, textarea, select
    border-radius: 6px
    height: 48px
    padding: 16px
    font-size: 15px
    color: #273D35
    border: 1px solid #CCCCCC
    caret-color: #2DBA87
    outline: none
    background: white
    font-family: 'Hiragino Kaku Gothic ProN'
    &::placeholder
      font-weight: 300
      font-size: 15px
      line-height: 20px
      color: #AAAAAA
    &:focus
      border: 1px solid #2DBA87
    &::-webkit-scrollbar
      display: none
.entry-fee
  display: flex
  align-items: center
  .yen
    color: #000000
    margin-left: 8px
.w-50
  width: 50%
</style>
