<template>
  <div class="notice-area">
    <div class="d-inline-table">
      <span class="user-join-name" @click="goToProfile(user.id)">{{ nameUserJoin(user) }}</span>
      <span class="user-join-content">さんが</span>
      <span class="event-name" @click="goToEvent(notice.organizationPath)">{{ notice.title }}</span>
      <span class="sharing-title">の予約受付を開始しました</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    notice: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('session', ['communityId'])
  },
  methods: {
    nameUserJoin (user) {
      if (user.commuNickname !== null && user.commuNickname !== '') {
        return user.commuNickname
      } else {
        return user.fullName
      }
    },
    goToProfile (id) {
      window.location.href = `/communities/${this.communityId}/users/${id}`
    },
    goToEvent (path) {
      window.location.href = path
    }
  }
}

</script>

<style scoped lang="sass">
  .notice-area
    width: 92%
    margin: auto
    display: flex
    justify-content: center
    align-items: center
    padding-bottom: 12px
  .d-inline-table
    display: inline-table
  .user-join-content
    position: relative
    text-align: center
    color: #273D35
    font-size: 12px
  .user-join-name
    color: #2DBA87
    font-weight: 600
    font-size: 12px
    padding-left: 7px
    word-break: break-all
  .event-name
    color: #2DBA87
    font-weight: 600
    font-size: 12px
  .sharing-title
    text-align: center
    color: #273D35
    font-size: 12px
</style>
