<template>
  <div class="detail-group">
    <div
      v-if="steps('init')"
      class="container-share-posts"
    >
      <div v-if="!this.isEdit">
        <div class="description">
          <span>モノをシェア</span>
        </div>

        <div class="sub-description">
          <span>どうしますか？</span>
        </div>

        <div class="content-share-posts">
          <div class="elements">
            <div
              class="col-2 element-item"
              :class="{'is-success': isActive('borrowings')}"
              @click="changeType('borrowings')"
            >
              <span>貸して</span>
            </div>
            <div
              class="col-2 element-item"
              :class="{'is-success': isActive('lendings')}"
              @click="changeType('lendings')"
            >
              <span>貸すよ</span>
            </div>
            <div
              class="col-2 element-item"
              :class="{'is-success': isActive('takings')}"
              @click="changeType('takings')"
            >
              <span>譲って</span>
            </div>
            <div
              class="col-2 element-item"
              :class="{'is-success': isActive('givings')}"
              @click="changeType('givings')"
            >
              <span>譲るよ</span>
            </div>
          </div>
        </div>

        <div class="columns is-mobile is-centered ">
          <div class="column line-black" />
        </div>
      </div>

      <Lending
        v-if="type === 'lendings'"
        :submit="fSubmit"
        :objects="objects"
        :time-current="this.timeCurrent"
      />
      <Giving
        v-if="type === 'givings'"
        :submit="fSubmit"
        :objects="objects"
      />
      <Borrowing
        v-if="type === 'borrowings'"
        :submit="fSubmit"

        :objects="objects"
      />

      <Taking
        v-if="type === 'takings'"
        :submit="fSubmit"
        :objects="objects"
      />
    </div>

    <div
      v-if="steps('preview')"
      class="container-share-preview"
    >
      <div class="description">
        <span>投稿する内容を確認してください</span>
      </div>

      <div class="container-share-preview--content container-type">
        <span class="container-share-preview--title">シェアの内容</span>
        <span class="container-type--element">{{ shareTypeText }}</span>
      </div>

      <div v-if="(objects.type === 'lendings' || objects.type === 'givings') && objects.images.length > 0">
        <div class="container-share-preview--content container-image">
          <span class="container-share-preview--title">画像</span>
          <div class="container-type--images">
            <div class="display-image">
              <div
                v-for="(image, index) in objects.images"
                :key="`images-${index}`"
              >
                <img :src="displayFile(image)">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-share-preview--content container-title">
        <span class="container-share-preview--title">モノの名前</span>
        <span class="container-type--element">
          {{ objects.title }}
        </span>
      </div>

      <div class="container-share-preview--content container-body">
        <span class="container-share-preview--title">メッセージ</span>
        <span class="container-type--element" v-html="formatText(objects.body)"></span>
      </div>

      <div v-if="objects.place">
        <div class="container-share-preview--content container-title">
          <span class="container-share-preview--title">受け渡し場所</span>
          <span class="container-type--element">
            {{ placeText }}
          </span>
        </div>
      </div>
      <div v-if="objects.type === 'lendings' && objects.date !== null">
        <div class="container-share-preview--content container-title">
          <span class="container-share-preview--title">お願いしたい返却日</span>
          <span class="container-type--element">
            {{ dateText }}
          </span>
        </div>
      </div>
      <div v-else-if="!['takings', 'lendings', 'givings'].includes(objects.type) && !objects.started_at">
        <div class="container-share-preview--content container-title">
          <span class="container-share-preview--title">お願いしたい返却日</span>
          <span class="container-type--element">
            {{ dateText }}
          </span>
        </div>
      </div>
      <div v-else-if="objects.started_at !== null && !['takings', 'lendings', 'givings'].includes(objects.type)">
        <div class="container-share-preview--content container-title">
          <span class="container-share-preview--title">お願いしたい返却日</span>
          <span class="container-type--element">
            {{ dateStarted }} 〜 {{ dateFinished }}
          </span>
        </div>
      </div>

      <div class="container-share-preview--content container-title">
        <span class="container-share-preview--title">希望謝礼額</span>
        <span class="container-type--element">{{ objects.reward }}円</span>
      </div>

      <div class="container-share-preview--content is-mt-5 is-mb-5 container-button">
        <div class="is-flex">
          <BButton
            type="submit"
            class="is-halfwidth btn-submit is-medium"
            @click="backInit"
          >
            修正する
          </BButton>
          <BButton
            type="submit"
            class="is-halfwidth is-success btn-submit is-medium"
            @click="submit"
          >
            投稿する
          </BButton>
        </div>
      </div>
    </div>

    <div
      v-if="steps('done') || steps('loading')"
      class="container-share-preview"
    >
      <BModal
        :active.sync="isComponentModalActive"
        has-modal-card
        :can-cancel="steps('done')"
        :on-cancel="redirectAfterCreate"
        width="62%"
        class="modal-done"
      >
        <div
          class="modal-card"
          style="width: auto"
        >
          <div class="modal-card-body">
            <div v-if="steps('loading')">
              <MyCommuLoading />
            </div>
            <div v-else>
              <img src="../../images/for_community/circle-check.png">
              <p class="text-default" v-text="textModal" />
            </div>
          </div>
        </div>
      </BModal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import axios from '../../utils/axios'
import { format, format as dateFormat, isSameYear } from 'date-fns'
import Lending from '../../components/my-commu/share_post/Lending'
import Giving from '../../components/my-commu/share_post/Giving'
import Borrowing from '../../components/my-commu/share_post/Borrowing'
import Taking from '../../components/my-commu/share_post/Taking'
import { showError } from '../../lib/app-toast'
export default {
  components: {
    Lending,
    Giving,
    Borrowing,
    Taking
  },
  props: {
    timeCurrent: {
      type: String,
      default: ''
    },
    objectSharing: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    isType: {
      type: String,
      default: 'borrowings'
    }
  },
  data () {
    const newObjects = {
      type: 'borrowings',
      images: [],
      title: null,
      body: null,
      place: null,
      place_confirm: false,
      date: null,
      date_confirm: false,
      started_at: null,
      finished_at: null,
      started_at_confirm: null,
      reward: null,
    }
    return {
      file: null,
      step: 'init', // init | preview | done,
      isComponentModalActive: false,
      objects: this.isEdit ? this.objectSharing : newObjects,
      method: this.isEdit ? 'PATCH' : 'POST',
      type: this.isType,
      id: this.isEdit ? this.objectSharing.id : null,
      isGroup: this.isEdit && this.objectSharing.isGroup,
      redirectPath: '',
    }
  },
  computed: {
    ...mapState('space', ['unsetMargin']),
    ...mapState('mycommu', ['currentCommunity', 'hiddenHeader']),
    ...mapState('session', ['communityId']),
    shareTypeText () {
      const typeTextTable = {
        lendings: '貸すよ',
        givings: '譲るよ',
        takings: '譲って',
        borrowings: '貸して'
      }
      return typeTextTable[this.type]
    },
    placeText () {
      return this.objects.place_confirm ? 'メッセージのやりとりで決める' : this.objects.place
    },
    dateText () {
      if (this.objects.date_confirm) {
        return 'メッセージのやりとりで決める'
      } else {
        const data = this.objects.date ? this.objects.date : this.objects.finished_at
        return dateFormat(data, 'YYYY/MM/DD')
      }
    },
    dateStarted () {
      const data = this.objects.started_at
      return (isSameYear(data, new Date()))
        ? dateFormat(data, 'M月D日 H:mm')
        : dateFormat(data, 'YYYY年M月D日 H:mm')
    },
    dateFinished () {
      const data = this.objects.finished_at
      return (isSameYear(data, new Date()))
        ? dateFormat(data, 'M月D日 H:mm')
        : dateFormat(data, 'YYYY年M月D日 H:mm')
    },
    inputValue () {
      return format(this.timeCurrent, 'YYYY-MM-DDTHH:mm:ssZ')
    },
    textModal () {
      return this.isEdit ? '修正されました' : '投稿されました'
    }
  },
  mounted () {
    this.unsetMargin.mainContainer = true
    this.unsetMargin.appMain = true
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.toggerMyCommuButton(false)
    this.toggerHeader(false)
    this.toggerMyCommuFooterMenu(true)
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader',
      'toggerMyCommuFooterMenu'
    ]),
    formatDateTime (date) {
      return (isSameYear(date, new Date()))
        ? dateFormat(date, 'M月D日 H:mm')
        : dateFormat(date, 'YYYY年M月D日 H:mm')
    },
    redirectAfterCreate () {
      const currentPath = location.pathname
      if (currentPath.includes('community_groups') || this.isGroup) {
        // memo: groupIdについて、何も外部に影響を与えていないように見える
        let groupId = 0
        if (this.isEdit) {
          groupId = this.isGroup
        } else {
          const pathGroup = currentPath.split('community_groups/')[1]
          groupId = pathGroup.split('/')[0]
        }
      }
      window.location.href = this.redirectPath
    },
    steps (key) {
      return this.step === key
    },
    toggleSwitch (key) {

    },
    displayFile (file) {
      if (file.url !== undefined) {
        return file.url
      } else {
        const url = URL.createObjectURL(file)
        URL.revokeObjectURL(file)
        return url
      }
    },
    formatText (text) {
      return text.replace(/\n/g, '<br>')
    },
    async submit () {
      if (this.step === 'init') {
        this.step = 'preview'
      } else {
        const submitFunc = {
          lendings: this.lendingsSubmit,
          givings: this.givingSubmit,
          borrowings: this.borrowingSubmit,
          takings: this.takingsSubmit
        }
        const doFunc = submitFunc[this.type]
        if (doFunc) {
          this.isComponentModalActive = true
          await doFunc()
        }
      }
    },
    fSubmit (data) {
      if (this.step === 'init') {
        this.objects = { ...data }
        this.step = 'preview'
      }
    },
    lendingsSubmit: async function () {
      if (this.isSubmitting) {
        return
      }
      this.isSubmitting = true

      const currentPath = location.pathname
      const form = new FormData()
      if (this.objects.images.length > 0) {
        const images = await this.fetchImages(this.objects.images)
        for (let index = 0; index < 4; index++) {
          form.append(`lendings[image${index + 1}]`, images[index])
        }
      }
      form.append('lendings[subject]', this.objects.title)
      form.append('lendings[note]', this.objects.body)
      if (this.objects.date !== null) {
        form.append('lendings[deadline_on]', this.objects.date)
      }
      form.append('lendings[place_name]', this.objects.place ? this.objects.place : '')
      form.append('lendings[reward_type]', this.objects.reward)
      if (currentPath.includes('community_groups')) {
        const pathGroup = currentPath.split('community_groups/')[1]
        const groupId = pathGroup.split('/')[0]
        const url = this.isEdit ? `/communities/${this.communityId}/community_groups/${groupId}/lendings/${this.id}`
          : `/communities/${this.communityId}/community_groups/${groupId}/lendings/`
        this.step = 'loading'
        await axios({
          method: this.method,
          url: url,
          data: form
        }).then((res) => {
          if (res.data.status) {
            this.redirectPath = res.data.redirectPath
            this.step = 'done'
          } else {
            showError('エラーが発生しました')
            window.location.reload()
          }
        }).catch(function (error) {
          console.log(error)
        })
      } else {
        const url = this.isEdit ? `/communities/${this.communityId}/share_posts/lendings/${this.id}`
          : `/communities/${this.communityId}/share_posts/lendings`
        form.append('organization_id', this.communityId)
        this.step = 'loading'
        await axios({
          method: this.method,
          url: url,
          data: form
        }).then((res) => {
          if (res.data.status) {
            this.redirectPath = res.data.redirectPath
            this.step = 'done'
          } else {
            showError('エラーが発生しました')
            window.location.reload()
          }
        }).catch(function (error) {
          console.log(error)
        })
      }
      this.isSubmitting = false
    },
    async fetchImages (images) {
      if (images.length > 0) {
        const promises = images.map(async (file) => {
          if (file.url === undefined) {
            return file
          } else {
            const response = await fetch(file.url)
            const blob = await response.blob()
            let fullUrl = file.url
            if (!/^https?:\/\//i.test(file.url)) {
              fullUrl = window.location.origin + file.url
            }

            const urlObject = new URL(fullUrl)
            const pathWithoutParams = urlObject.pathname
            const filename = pathWithoutParams.substring(pathWithoutParams.lastIndexOf('/') + 1)

            const fileConvert = new File([blob], filename, { type: blob.type })
            return fileConvert
          }
        })
        return Promise.all(promises)
      }
    },
    async givingSubmit () {
      if (this.isSubmitting) {
        return
      }
      this.isSubmitting = true

      const currentPath = location.pathname
      const form = new FormData()
      if (this.objects.images.length > 0) {
        const images = await this.fetchImages(this.objects.images)
        images.forEach((file, index) => {
          form.append(`givings[image${index + 1}`, file)
        })
      }
      if (this.isEdit) {
        const countImageNew = this.objects.images.length
        const countImageOld = this.objectSharing.images.length
        for (let i = countImageNew; i < countImageOld; i++) {
          form.append(`givings[remove_image${i}]`, '1')
        }
      }
      form.append('givings[subject]', this.objects.title)
      form.append('givings[note]', this.objects.body)
      form.append('givings[place_name]', this.objects.place ? this.objects.place : '')
      form.append('givings[reward_type]', this.objects.reward)
      if (currentPath.includes('community_groups')) {
        const pathGroup = currentPath.split('community_groups/')[1]
        const groupId = pathGroup.split('/')[0]
        const url = this.isEdit ? `/communities/${this.communityId}/community_groups/${groupId}/givings/${this.id}`
          : `/communities/${this.communityId}/community_groups/${groupId}/givings`
        this.step = 'loading'
        await axios({
          method: this.method,
          url: url,
          data: form
        }).then((res) => {
          if (res.data.status) {
            this.redirectPath = res.data.redirectPath
            this.step = 'done'
          } else {
            showError('エラーが発生しました')
            window.location.reload()
          }
        }).catch(function (error) {
          console.log(error)
        })
      } else {
        const url = this.isEdit ? `/communities/${this.communityId}/share_posts/givings/${this.id}`
          : `/communities/${this.communityId}/share_posts/givings`
        form.append('organization_id', this.communityId)
        this.step = 'loading'
        await axios({
          method: this.method,
          url: url,
          data: form
        }).then((res) => {
          if (res.data.status) {
            this.redirectPath = res.data.redirectPath
            this.step = 'done'
          } else {
            showError('エラーが発生しました')
            window.location.reload()
          }
        }).catch(function (error) {
          console.log(error)
        })
      }
      this.isSubmitting = false
    },
    async borrowingSubmit () {
      if (this.isSubmitting) {
        return
      }
      this.isSubmitting = true

      const currentPath = location.pathname
      const form = new FormData()

      form.append('borrowings[subject]', this.objects.title)
      form.append('borrowings[note]', this.objects.body)
      form.append('borrowings[started_at]', this.objects.started_at)
      form.append('borrowings[finished_at]', this.objects.finished_at)
      form.append('borrowings[reward_type]', this.objects.reward)
      if (currentPath.includes('community_groups')) {
        const pathGroup = currentPath.split('community_groups/')[1]
        const groupId = pathGroup.split('/')[0]
        const url = this.isEdit ? `/communities/${this.communityId}/community_groups/${groupId}/borrowings/${this.id}`
          : `/communities/${this.communityId}/community_groups/${groupId}/borrowings`
        this.step = 'loading'
        await axios({
          method: this.method,
          url: url,
          data: form
        }).then((res) => {
          if (res.data.status) {
            this.redirectPath = res.data.redirectPath
            this.step = 'done'
          } else {
            showError('エラーが発生しました')
            window.location.reload()
          }
        }).catch(function (error) {
          console.log(error)
        })
      } else {
        form.append('organization_id', this.communityId)
        const url = this.isEdit ? `/communities/${this.communityId}/share_posts/borrowings/${this.id}`
          : `/communities/${this.communityId}/share_posts/borrowings`
        this.step = 'loading'
        await axios({
          method: this.method,
          url: url,
          data: form
        }).then((res) => {
          if (res.data.status) {
            this.redirectPath = res.data.redirectPath
            this.step = 'done'
          } else {
            showError('エラーが発生しました')
            window.location.reload()
          }
        }).catch(function (error) {
          console.log(error)
        })
      }
      this.isSubmitting = false
    },

    async takingsSubmit () {
      if (this.isSubmitting) {
        return
      }
      this.isSubmitting = true

      const form = new FormData()
      const currentPath = location.pathname
      form.append('takings[subject]', this.objects.title)
      form.append('takings[note]', this.objects.body)
      form.append('takings[reward_type]', this.objects.reward)
      if (currentPath.includes('community_groups')) {
        const pathGroup = currentPath.split('community_groups/')[1]
        const groupId = pathGroup.split('/')[0]
        const url = this.isEdit ? `/communities/${this.communityId}/community_groups/${groupId}/takings/${this.id}`
          : `/communities/${this.communityId}/community_groups/${groupId}/takings`
        this.step = 'loading'
        await axios({
          method: this.method,
          url: url,
          data: form
        }).then((res) => {
          if (res.data.status) {
            this.redirectPath = res.data.redirectPath
            this.step = 'done'
          } else {
            showError('エラーが発生しました')
            window.location.reload()
          }
        }).catch(function (error) {
          console.log(error)
        })
      } else {
        form.append('organization_id', this.communityId)
        const url = this.isEdit ? `/communities/${this.communityId}/share_posts/takings/${this.id}`
          : `/communities/${this.communityId}/share_posts/takings`
        this.step = 'loading'
        await axios({
          method: this.method,
          url: url,
          data: form
        }).then((res) => {
          if (res.data.status) {
            this.redirectPath = res.data.redirectPath
            this.step = 'done'
          } else {
            showError('エラーが発生しました')
            window.location.reload()
          }
        }).catch(function (error) {
          console.log(error)
        })
      }
      this.isSubmitting = false
    },

    backInit () {
      this.step = 'init'
    },
    changeType (type) {
      if (!this.isEdit) {
        this.type = type
      }
    },
    isActive (type) {
      return this.type === type
    }
  }
}
</script>
<style scoped lang="sass">

.container-share-posts
  padding: 0px 32px 62px 32px
  color: #424242

  .description
    padding-top: 40px
    padding-bottom: 30px
    text-align: center

    span
      font-weight: 600
      font-size: 17px

  .sub-description
    padding-bottom: 20px
    text-align: center

    span
      font-weight: 300
      font-size: 15px
      color: #273D35

  .elements
    padding: 0px
    display: grid
    gap: 15px
    grid-template-columns: auto auto
    padding-bottom: 30px

    .element-item
      background: #FFFFFF
      box-shadow: 0px 2px 8px #f2efde
      border-radius: 6px
      padding: 12.75px
      display: flex
      flex-direction: column

      align-items: center
      color: #2DBA87
      text-align: center
      font-size: 15px
      font-weight: 700

      &.is-success
        background: #2DBA87
        color: #FFFFFF

      img
        padding-bottom: 15px

.switch-custom
  display: flex
  justify-content: right
  align-items: center

.line-black
  border-top: 1px solid #CCCCCC

.content-share-posts
  .description
    padding: 0px
    padding-bottom: 32px

label
  color: #273D35
  font-weight: 400
  font-size: 14px

.file-cta
  height: 48px
  color: #2DBA87 !important

  .icon
    font-size: 18px
    padding: 0
    margin: 0

.columns
  margin-top: 10px
  margin-bottom: 0px !important

.post-title
  margin-top: 24px

.is-orange
  color: #F78F54
  font-weight: 700
  font-size: 12px

.switch-columns
  margin-top: 0px

.condition-columns
  span
    font-weight: 600
    font-size: 12px

.column-accep
  .column
    display: flex

    span
      margin-left: 10px

.container-share-preview
  color: #273D35
  padding: 0px 32px

  .description
    display: flex
    justify-content: center
    margin-top: 40px
    font-weight: 300
    font-size: 15px
    margin-bottom: 25px

  .container-type--images
    margin-top: 8px

    .display-image
      display: flex

      img
        box-shadow: 0px 2px 8px #F2EFDE
        border: 1px solid #7C7C7C
        width: 48px
        height: 48px
        border-radius: 6px
        margin-right: 16px

  .container-share-preview--content
    display: flex
    padding-top: 24px
    flex-direction: column

    &.container-button
      margin-top: 100px

  .container-share-preview--title
    font-weight: 600
    font-size: 14px

  .is-halfwidth
    width: 50%
    margin-right: 10px

  .button
    color: #2DBA87
    font-weight: 700
    font-size: 15px
    height: 48px

    &.is-success
      color: #ffffff
.modal-done
  .modal-card-body
    color: #273D35
    font-size: 15px
    border-radius: 20px
    text-align: center
    padding: 40px 16px
    img
      margin-bottom: 20px
      height: 66px
      width: 66px
  /deep/
    .modal-close
      display: none

.upload-images
  .field
    flex-direction: column
    display: flex

    .display-image
      display: flex

      img
        box-shadow: 0px 2px 8px #F2EFDE
        border: 1px solid #7C7C7C
        width: 48px
        height: 48px
        border-radius: 6px
        margin-right: 16px

.event-label, .datepicker-event
  font-weight: 300
  font-size: 14px
  line-height: 14px
  color: #273D35
  display: flex
  justify-content: space-between
  align-items: center

.switch
  margin-left: 10px
/deep/
  .modal-background
    background: #273D35
    opacity: 0.9
</style>
