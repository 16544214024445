import { render, staticRenderFns } from "./ChildTicket.vue?vue&type=template&id=6bde7b50&scoped=true&"
import script from "./ChildTicket.vue?vue&type=script&lang=js&"
export * from "./ChildTicket.vue?vue&type=script&lang=js&"
import style0 from "./ChildTicket.vue?vue&type=style&index=0&id=6bde7b50&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bde7b50",
  null
  
)

export default component.exports