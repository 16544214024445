<template>
  <div>
    <MyCommuHiddenButtonFooter
      :is-my-commu-domain="true"
    />
    <MyCommuHiddenFooterMenu
      :is-my-commu-domain="true"
    />
    <HiddenHeader/>
    <div class="topbar">
      <div class="topbar-icon-back" @click="back()">
        <svg width="21" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.0697 3.8501L7.92969 12.0001L16.0697 20.1501" stroke="#2DBA87" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          </path>
        </svg>
      </div>
      <div class="topbar-title">
        <span>{{ chat.title }}</span>
      </div>
      <div>
        <b-dropdown aria-role="list" can-close="true" class="action-chat">
          <template #trigger>
            <img src="../../../images/icons/three_dots.png" alt="" width="24">
          </template>
          <b-dropdown-item aria-role="listitem" @click="redirectToReportChat()">
            <span class="icon">
              <img src="../../../images/icons/report.svg">
            </span>
            <p>通報する</p>
          </b-dropdown-item>
          <b-dropdown-item v-if="!isBlock" aria-role="listitem" class="dropdown-last" @click="blockUser(partner)">
            <span class="icon">
              <img src="../../../images/icons/block_chat.svg">
            </span>
            <p>ブロックする</p>
          </b-dropdown-item>
          <b-dropdown-item v-else aria-role="listitem" class="dropdown-last" @click="unblockUser(partner)">
            <span class="icon">
              <img src="../../../images/icons/block_chat.svg">
            </span>
            <p>ブロックを解除する</p>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div
      class="list-message scroll-container"
      :class="{'list-message-no-input': chat.blocked, 'height-for-chat-share': chat.sourceShare}"
      id="list-message"
      ref="scroll-container"
      v-touch:swipe="swipHorizontal"
      v-touch:end="endHandler"
      v-touch:start="startHandler"
    >
      <div
        v-if="loadMore"
        class="loadmores"
      >
        <img src="../../../images/for_community/spinner.svg">
      </div>
      <div class="event-card" v-if="chat.sourceShare.event">
        <MyCommuCardEventContent :path="chat.sourceShare.organizationPath" :event="chat.sourceShare.event"/>
      </div>
      <div class="m-16 navigation-text" v-if="chat.sourceShare.sharingType !== undefined">
        <div v-if="chat.sourceShare.isAuthor && chat.sourceShare.sharingType !== 'casting'">
          <div>{{ `「${shareType}」に${chat.partner.commuNickname}さんが立候補しました。` }}</div>
          <div>立候補に対して相談や質問があればメッセージを送りましょう。</div>
        </div>
        <div v-if="!chat.sourceShare.isAuthor && chat.sourceShare.sharingType !== 'casting' && chat.sourceShare.isClosed">
          <div>募集終了</div>
          <div>立候補ありがとうございました！</div>
        </div>
        <div v-if="chat.sourceShare.isClosed || (chat.sourceShare.isAuthor && chat.partner.isDecided) "
            class="notification-decided">
          <img src="../../../images/for_community/circle_check_transparent.png" alt="">
          <div>シェアリングが成立しました！</div>
        </div>
        <div v-if="chat.sourceShare.sharingType !== 'casting' && chat.sourceShare.isClosed">
          <div>受け渡し日時や場所の確認をコメントして、待ち合わせしましょう。相手のことを考え、丁寧なコメントでシェアリング終了までご利用ください。</div>
        </div>
      </div>
      <div v-for="(message, index) in chatMessages" :key="message.id">
        <div class="bjf-notidate"
          v-if="compareDate(index)"
        >
          <span>{{formatDate(message.createAt)}}</span>
        </div>
        <MyCommuChatsGuestMessage
          v-if="!formHost(message)"
          :chat-message="message"
          @react="react"
        />
        <MyCommuChatsHostMessage v-if="formHost(message)" :chat-message="message"/>
      </div>
    </div>

    <MyCommuInputMessageBox
      v-if="!chat.blocked"
      @submit="doCreateChatMessage"
      @uploadFile="doCreateImageChatMessage"
    />
    <ShareCard
        v-if="chat.sourceShare"
        class="m-16"
        :share="chat.sourceShare"
        :isDetailChat="true"
        share-path="#"
    />
    <div class="tutorial-wrapper fade-in" v-if="!chat.sourceShare.isExpired && chat.sourceShare.isAuthor && !chat.partner.isDecided && tutorial" @click="hideTutorial">
      <div class="tutorial-1">
        <div class="header">
          <span>シェアする相手を決めた場合はこちらのボタンをクリックして決定してください</span>
        </div>
      </div>
      <div class="polygon-down"><img src="../../../images/icons/polygon-2.svg" alt="Polygon"></div>
    </div>
    <div class="create-ticket" v-if="!chat.sourceShare.isExpired && chat.sourceShare.isAuthor && !chat.partner.isDecided">
      <button class="button" @click="modal = true">
        シェアする相手をこの人に決定する
      </button>
    </div>
    <b-modal
      :active.sync="modal"
      has-modal-card
      :can-cancel="false"
    >
      <div class="modal-card">
        <div class="modal-card-body">
          <div class="modal-card-body-content">
            <p>シェアする相手を決定します。</p> 
            <p>この募集は終了しますか？</p>
          </div>
          <div class="modal-btn">
            <button @click="action(false)">いいえ</button>
            <button @click="action(true)">はい</button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
        :active.sync="lastModal"
        has-modal-card
        :on-cancel="redirectPage"
    >
      <div class="modal-card">
        <div class="modal-card-body">
          <img src="../../../images/icons/success.svg" class="success-img">
          <div class="modal-card-body-content">
            <p v-if="endSharing">この募集を終了しました。</p> 
            <p v-else>この募集を継続します。</p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import { getMessages } from "../../../apis/chat"
import {format as dateFormat, format} from 'date-fns'
import ShareCard from "../../../page/SharePost/ShareCard.vue"
import { commuSuccess, commuError } from 'lib/app-toast'
import axios from 'axios'

export default {
  components: {
    ShareCard
  },
  props: {
    chat: {
      type: Object,
      required: true
    },
    isBlock: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      message: '',
      chatMessages: this.chat.chatMessages,
      partner: this.chat.partner,
      loadMore: false,
      shareType: '',
      modal: false,
      redirectPath : '',
      endSharing: null,
      lastModal: false,
      tutorial: true
    }
  },
  computed: {
    ...mapState('mycommu', [
      'friends',
      'titleHeader'
    ]),
    ...mapState('chat', ['currentChat']),
    ...mapState('session', ['currentUser', 'communityId'])
  },
  created () {
    this.initChat(this.chat)
    this.isCommuChatDetail(true)
  },
  watch: {
    chatMessages () {
      if(!this.loadMore){
        this.$nextTick(() => {
          this.scrollBottom()
        })
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.tutorial = false
    }, 5000)
    this.scrollBottom()
    switch (this.chat.sourceShare.sharingType) {
      case 'borrowing':
        this.shareType = '貸して'
        break
      case 'entry':
        this.shareType = '預かって'
        break
      case 'lending':
        this.shareType = '貸すよ'
        break
      case 'giving':
        this.shareType = '譲るよ'
        break
      case 'taking':
        this.shareType = '譲って'
        break
    }
  },
  methods: {
    ...mapActions('chat', [
      'initChat',
      'commuCreateChatMessage',
      'commuCreateImageChatMessage',
      'reactMessage'
    ]),
    ...mapActions('mycommu', [
      'isCommuChatDetail'
    ]),
    doCreateChatMessage (message) {
      const msg = message.trim()
      this.commuCreateChatMessage(msg)
      this.scrollBottom()
    },
    formHost (message) {
      return this.currentUser.id === message.writerId
    },
    doCreateImageChatMessage (file) {
      this.commuCreateImageChatMessage(file)
      this.scrollBottom()
    },
    scrollBottom () {
      const objDiv = document.getElementById('list-message')
      objDiv.scrollTop = objDiv.scrollHeight
    },
    back () {
      if(document.referrer !== '' && window.location.href !== document.referrer) {
        // window.location.href = document.referrer
        window.history.back()
      } else {
        window.location.href = '/communities/chats'
      }
    },
    react (type, id) {
      if (!this.chat.blocked) {
        this.reactMessage({ messageId: id, type: type })
      }
    },
    redirectToReportChat() {
      window.location.href = 'https://ws.formzu.net/fgen/S77589438/'
    },
    blockUser (partner) {
      axios.put(`/users/${partner.id}/one_to_one_chat/block.json`).then((respone) => {
        window.location.href = '/communities/chats'
      })
    },
    unblockUser (partner) {
      axios.put(`/users/${partner.id}/one_to_one_chat/unblock.json`).then((respone) => {
        window.location.reload() 
      })
    },
    swipHorizontal(direction) {
      return;
    },
    startHandler(node) {
      if (node.touches && node.touches.length > 0) {
        const [{ pageY }] = node.touches;
        this.startPosition = pageY;
      } else {
        this.startPosition = null;
      }
    },
    endHandler(node) {
      if (node.changedTouches && node.changedTouches.length > 0) {
        const [{pageY}] = node.changedTouches
        this.endPosition = pageY
        this.handleLoadMores()
      } else {
        this.endPosition = null;
      }
    },
    async handleLoadMores() {
      const dist = this.endPosition - this.startPosition
      const el = this.$refs['scroll-container'];
      const abs = Math.abs(dist) + this.startPosition

      if(dist > 0 && !this.loadMore){
        this.loadMore = true
        const first_id = this.chatMessages[0]?.id
        const createAt = this.chatMessages[0]?.createAt
        const params = {
          message: {
            message_id: first_id,
            created_at: createAt
          }
        }
        await getMessages(this.chat.id, params).then((respone) => {
          this.chatMessages = [...respone.data, ...this.chatMessages]
        }).then(() => {
          this.loadMore = false
        })
      }

    },
    compareDate(index) {
      if(index === 0){
        return true
      }
      const dest = this.chatMessages[index]?.createAt
      const src = this.chatMessages[index - 1]?.createAt
      const f_dest = format(dest, 'YYYY.MM.DD')
      const f_src = format(src, 'YYYY.MM.DD')
      return f_dest !== f_src
    },
    formatDate(date) {
      return format(date, 'YYYY.MM.DD')
    },
    async action (type) {
      this.endSharing = type
      const data = {
        share: {
          id: this.chat.sourceShare.id,
          type: this.chat.sourceShare.sharingType,
        },
        source: {
          id: this.chat.sourceId
        }
      }
      await axios.post(`/communities/${this.communityId}/share_posts/handle_decided`,data).then((res) => {
        this.modal = false
        this.redirectPath = res.data
      })
      if (type) {
        axios.put(`${this.chat.sourceShare.pathClose}`)
      }
      this.lastModal = true
    },
    redirectPage () {
      window.location.href = this.redirectPath
    },
    hideTutorial () {
      this.tutorial = false
    },
    toLastModal () {
      this.lastModal = true
    }
  }
}
</script>

<style scoped lang="sass">
  .topbar
    display: flex
    padding: 13px 15px 4px 15px
    background-color: white
    position: fixed
    width: 100%
    .topbar-title
      color: #273D35
      font-weight: bold
      font-size: 16px
      text-align: center
      width: 100%
      justify-content: center
    .action-chat
      /deep/
        .dropdown-menu
          min-width: 157px
          width: unset !important
          top: 65px !important
          left: auto !important
          border: 1px solid #AAAAAA
          box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.3)
          border-radius: 8px
          padding: unset
          transform: translate3d(0%, -25%, 0)
          right: 35px !important
          .dropdown-content
            padding: unset
            .dropdown-item
              padding: 1rem 1rem 0rem 1rem
              display: flex
              span
                color: #FFFFFF
              p
                color: #273D35
                margin-left: 10px
                font-weight: 300
                font-size: 14px
                display: flex
                align-items: center
            .dropdown-last
              padding-bottom: 1rem
  .list-message
    padding-top: 70px
    height: calc(100vh - 98px)
    overflow: scroll !important
    &.height-for-chat-share
      height: calc(100vh - 330px) !important
  .list-message-no-input
    height: 100vh
  .loadmores
    display: flex
    justify-content: center
  .bjf-notidate
    display: flex
    justify-content: center
    font-weight: 700
    color: #273D35
    font-size: 12px
  .m-16
    margin: 16px
  .navigation-text
    font-size: 12px
    color: #2DBA87
  .tutorial-1, .tutorial-2
    background: #F68F54
    border-radius: 10px
    margin: 10px 17px 0
    padding: 30px
    align-self: end
    color: white
    .header
      display: flex
      justify-content: space-between
      align-items: start
      font-weight: bold
      align-items: center
    .content
      font-weight: 300
  .polygon-down
    text-align: center
    margin-top: -2px
  .create-ticket
    background: linear-gradient(360deg, #CBC8AB 0%, rgba(196, 196, 196, 0) 100%)
    padding: 9px 16px 8px 16px
    position: sticky
    bottom: 0
    left: 0
    display: flex
    align-items: self-end
    height: 60px
    z-index: 100
    .button
      width: 100%
      background: #2DBA87
      border: none
      box-shadow: 0 2px 8px #f2efde
      border-radius: 6px
      padding: 25px
      color: #FFFFFF
      font-weight: bold
      font-size: 15px
  /deep/
    .modal-background
      background-color: #273D35
      opacity: 0.9
    .modal-close
      display: none
  .modal-card
    font-size: 14px
    line-height: 24px
    display: flex
    justify-content: center
    padding: 0 52px
    .modal-card-body
      text-align: center
      border-radius: 20px
      padding: unset
      &-content
        padding: 32px 20px
      .success-img
          margin-top: 40px
      /deep/
        .modal-btn
          display: flex
          font-family: 'Hiragino Kaku Gothic ProN'
          font-style: normal
          font-weight: 300
          text-align: center
          button
            border: unset
            border-top: 1px solid #cccccc
            font-size: 15px
            width: 50%
            background: white
            color: #2DBA87
            padding: 20px 0 !important
            &:first-child
              border-right: 1px solid #cccccc
            &:last-child
              font-weight: bold
  .notification-decided
    display: flex
    margin: 16px
    border: 1px solid #2DBA87
    padding: 8px
    font-family: 'Hiragino Kaku Gothic ProN'
    font-style: normal
    font-weight: 600
    font-size: 12px
    align-items: center
    color: #2DBA87
    background: white
    .text-confirmed
      color: #273D35
      font-weight: 300
      margin-top: 16px
    img
      width: 20px
      height: 20px
      margin-right: 16px
  .mt-24
    margin-top: 24px
  .mt-16
    margin-top: 16px
  .mt-8
    margin-top: 8px
  .fade-in
    position: fixed
    bottom: 55px
</style>
