<template>
  <div class="user-requests-new">
    <Back title="相談窓口"/>
    <div class="user-requests-new-container">
      <div class="column is-11 has-text-left description">
        <div>アプリ操作で分からないこと、ご意見・ご要望、エラー・トラブルなど、お気軽に送信ください。</div>
      </div>

      <div class="user-requests-new-form">
        <div class="form-element user-requests-new-form-description">
          <BField class="label-ml-10px" label="相談内容">
            <b-input v-model="description" type="textarea" :placeholder="placeholderDescription"></b-input>
          </BField>
        </div>
        <div class="form-element user-requests-new-form-description upload-field">
          <div class="label-upload">
            <div>画像設定</div>
            <MyCommuOptional />
          </div>
          <p class="image-note">JPG/JPEG・PNG・GIF形式、最大10MBまで</p>
          <div class="upload-area">
            <BField class="is-white file pt-5px mr-16px">
              <BUpload
                v-model="files"
                class="file-label file-upload"
                :multiple="true"
                accept=".jpg, .png, .jpeg, .gif"
              >
                <span
                  class="file-cta"
                >
                  <BIcon
                    class="file-icon"
                    icon="plus"
                  />
                </span>
              </BUpload>
            </BField>
            <div class="preview-images">
              <div
                v-for="(file, index) in files"
                :key="index"
                class="image-upload"
              >
                <img
                  :src="getUrl(file)"
                  class="img"
                >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="removeImage(index)"
                >
                  <path
                    d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                    fill="white"
                    stroke="#273D35"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 6L10 10"
                    stroke="#273D35"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 6L6 10"
                    stroke="#273D35"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="user-requests-new-form-submit">
          <BButton type="submit" @click="submit" :disabled="checkInput" class="is-fullwidth is-success">
            送信する
          </BButton>
        </div>
      </div>
    </div>
    <div class="column is-11 text-center description">
      <span>【お急ぎの場合】電話受付 070-6968-7594</span>
      <br>
      <span>9:00～18:00（年末年始、土日・祝日除く）</span>
    </div>

    <BModal
      :active.sync="isComponentModalActive"
      has-modal-card
      :can-cancel="true"
      :on-cancel="redirectToHome"
      width="70%"
    >
      <div class="modal-card" style="width: auto">
        <div class="modal-card-body">
          <img src="../../../images/for_community/circle-check.png">
          <p class="text-default">
            送信しました
            <br>
            相談ありがとうございます
          </p>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Back from '../Back.vue'
import axios from '../../../utils/axios'
import { compressUploadedImage } from '../../../lib/compress_uploaded_image'

export default {
  components: {
    Back
  },
  props: {},
  computed: {
    ...mapState('mycommu', ['currentCommunity', 'hiddenHeader', 'unsetPadding']),
    ...mapState('session', ['communityId', 'currentUser']),
    ...mapState('space', ['unsetPadding', 'unsetMargin']),
    checkInput () {
      return (this.description === null || this.description === '')
    },
    placeholderDescription () {
      return '相談内容を具体的にご入力ください。\n' +
        'エラーやトラブルの場合は、発生するまでの手順やOSバージョン・機種など入力いただいた内容をもとに確認いたします。'
    }
  },
  data () {
    return {
      isComponentModalActive: false,
      files: [],
      description: null
    }
  },
  created () {
  },
  mounted () {
    this.toggerMyCommuButton(false)
    this.toggerHeader(true)
    this.hiddenHeader.mainContainerUnsetMargin = true
    this.unsetPadding.mainContainer = true
  },
  methods: {
    ...mapActions('mycommu', [
      'toggerMyCommuButton',
      'toggerHeader'
    ]),
    async submit () {
      const form = new FormData()
      for (const [index, file] of Object.entries(Array.from(this.files))) {
        const compressed = await compressUploadedImage(file)
        form.append(`user_request[image${index + 1}]`, compressed)
      }
      form.append('user_request[description]', this.description)
      form.append('user_request[number_of_images]', this.files.length)
      const method = 'POST'
      const url = `/communities/${this.communityId}/user_requests`
      await axios({
        method: method,
        url: url,
        data: form
      }).then((res) => {
        if (res.data.status) {
          this.isComponentModalActive = true
        }
      })
    },
    redirectToHome () {
      window.location.href = '/'
    },
    removeImage (index) {
      this.files.splice(index, 1)
    },
    getUrl (e) {
      const url = URL.createObjectURL(e)
      URL.revokeObjectURL(e)
      return url
    }
  }
}
</script>
<style scoped lang="sass">
$label-color: #f8f7f2 !default
$label-weight: 400 !default
/deep/
.switch input[type=checkbox]:checked + .check:before
  transform: translate3d(70%, 0, 0)

.user-requests-new
  background: #f8f7f2
  font-size: 15px
  color: #273D35
  font-weight: 400
  overflow: scroll

  .label
    color: #273D35
    font-weight: 400

  .user-requests-new-container
    margin: 40px 23px 0px 23px
    font-size: 14px

    .description
      font-size: 15px
      font-weight: 300
      padding: 0

    .user-requests-new-form
      margin-top: 40px

      .form-element
        margin-top: 24px

        /deep/
        .file
          display: unset

          .file-upload
            width: 48px
            height: 48px
            box-shadow: 0px 2px 8px #f2efde
            border-radius: 6px

            .file-cta
              width: 100%
              height: 100%

          .label
            font-size: 14px
            cursor: pointer

          .file-upload
            .file-icon
              margin: auto
              color: #2DBA87

      .user-requests-new-form-submit
        padding: 32px 0
        border-radius: unset

        .memo
          margin-bottom: 32px
          color: #2DBA87
          font-size: 12px

        /deep/
        button
          border-radius: 6px
          height: 48px

      .columns
        justify-content: space-between
        margin-right: unset

        /deep/
        span.check
          height: 31px
          width: 51px

          &:before
            width: 27px
            height: 27px

    .user-requests-new-form-name
      /deep/
      input
        height: 48px
        caret-color: #2DBA87
        box-shadow: unset
        border-color: #cccccc
        border-radius: 6px

        input:focus
          border-color: #2DBA87
          box-shadow: unset
    .upload-field
      padding: 0 10px
    .user-requests-new-form-description
      /deep/
      input
        height: 48px
        caret-color: #2DBA87
        box-shadow: unset
        border-color: #cccccc
        border-radius: 6px

        input:focus
          border-color: #2DBA87
          box-shadow: unset

        textarea
          caret-color: #2DBA87
          height: 130px
          resize: none
          box-shadow: unset
          border-color: #cccccc
        border-radius: 6px

        textarea:focus
          border-color: #2DBA87
          box-shadow: unset

  /deep/
  .modal
    z-index: 1997
    .modal-background
      background: #273D35
      opacity: 0.9

      .modal-close
        display: none

@media screen and (max-width: 768px)
  /deep/
  .modal .animation-content
    width: unset
.modal-card
  align-items: center
.modal-card-body
  //display: flex
  //justify-content: center
  //align-items: center
  //flex-direction: column
  color: #273D35
  font-size: 15px
  border-radius: 20px
  text-align: center
  padding: 40px 16px
  width: 230px

  img
    margin-bottom: 20px
    height: 66px
    width: 66px

.is-success
  /deep/ span
    font-weight: bold

.upload-area
  display: flex

  .preview
    margin-left: 16px
    position: relative

    .image-preview
      height: 48px
      width: 48px
      border: 1px solid #7C7C7C
      box-shadow: 0 2px 8px #F2EFDE
      border-radius: 6px

    .delete-preview
      position: absolute
      right: -8px
      top: -8px

.border-0
  border: unset !important

.preview-images
  max-width: 100%
  display: -webkit-box
  overflow-x: scroll
  padding-top: 5px

  .image-upload
    position: relative
    border-radius: 6px
    margin-right: 16px

    .img
      width: 48px
      height: 48px
      object-fit: cover
      border-radius: 6px
      border: 1px solid #7C7C7C

    svg
      position: absolute
      left: 38px
      top: -5.5px

.pt-5px
  padding-top: 5px
.mr-16px
  margin-right: 16px
.label-ml-10px
  /deep/
    .label
      font-size: 15px
      margin-left: 10px
.label-upload
  display: flex
  font-size: 15px
  justify-content: space-between
/deep/

.image-note
  font-size: 12px
  color: #2DBA87
  margin: 8px 0 16px

.invalid-image
  font-size: 14px
  color: red
  font-weight: 600
  margin-bottom: 16px
</style>
